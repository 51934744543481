import { useLazyQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { UserContext } from '@faceup/member'
import { useNavigate, useSearchParams } from '@faceup/router'
import { ucLoader } from '@faceup/ui'
import { Button, Modal, Result, notification } from '@faceup/ui-base'
import {
  CHARGEBEE_REDIRECT_INSTITUTION_ID_PARAM_NAME,
  CHARGEBEE_REDIRECT_PARTNER_PARAM_NAME,
  ChargebeeSubscriptionStatus,
} from '@faceup/utils'
import { useContext, useEffect, useMemo, useState } from 'react'
import { sharedMessages } from '../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../TypedIntl'
import { graphql } from '../__generated__'
import { useMotherId } from '../hooks/useMotherId'
import useAnalytics from '../utils/analytics'

const messages = defineMessages({
  success: 'Administration.redirectFromChargebee.success',
  waitingForResponse: 'Administration.redirectFromChargebee.waitingForResponse',
  buttonClose: 'Administration.redirectFromChargebee.buttonClose',
})

const query = {
  RedirectFromChargebeeQuery: graphql(`
    query RedirectFromChargebeeQuery($motherId: UUID!) {
      memberViewer {
        id
        mother(motherId: $motherId) {
          id
          country

          billing {
            id
            subscriptionEnd
            subscriptionStatus
            currency
            employees
            plan
            frequency
            price
          }
        }
      }
    }
  `),
  RedirectFromChargebeeInstitutionsQuery: graphql(`
    query RedirectFromChargebeeInstitutionsQuery {
      memberViewer {
        id
        institutionsOverview {
          id

          billing {
            id
            subscriptionEnd
            subscriptionStatus
            currency
            employees
            plan
            frequency
            price
          }
        }
      }
    }
  `),
}

const RedirectFromChargebee = () => {
  const [modal, setModal] = useState<'waiting' | 'success'>()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { setMotherId, getMotherIdWithNull } = useMotherId()
  const { viewer } = useContext(UserContext)
  const { formatMessage } = useIntl()
  const { trackPurchaseCompleted } = useAnalytics()
  const [institutionIdFromUrl, setInstitutionIdFromUrl] = useState<string | null>(null)
  const motherIdWithNull = getMotherIdWithNull()

  const [, { startPolling, stopPolling }] = useLazyQuery(query.RedirectFromChargebeeQuery, {
    variables: {
      // It's ugly because same component is used in partner administration
      motherId: motherIdWithNull ?? '',
    },
    /*
     * For unknown reasons, the query is called, when we click `Go back to partner administration`
     * and we had previously pooled query.
     * Mother id is set to null and query is re-fetched - we do not want to fetch query in this case.
     */
    fetchPolicy: motherIdWithNull ? undefined : 'standby',
    // https://github.com/apollographql/apollo-client/issues/5531#issuecomment-568235629
    notifyOnNetworkStatusChange: true,
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: data => {
      const billing = data?.memberViewer?.mother?.billing
      const areEmployeesSet = (billing?.employees ?? 0) > 1

      if (
        billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Active &&
        areEmployeesSet &&
        // do not ask me why we need to check, whether `modal` is undefined, but it's necessary, because `onCompleted` is called multiple times
        // it looks like a bug in Apollo Client - https://github.com/apollographql/apollo-client/issues/9338
        modal
      ) {
        trackPurchaseCompleted(billing.plan)
        setModal('success')
        stopPolling()
      }
    },
  })

  const [, { startPolling: startInstitutionsPolling, stopPolling: stopInstitutionsPolling }] =
    useLazyQuery(query.RedirectFromChargebeeInstitutionsQuery, {
      // https://github.com/apollographql/apollo-client/issues/5531#issuecomment-568235629
      notifyOnNetworkStatusChange: true,
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted: data => {
        const viewer = data.memberViewer
        const company = viewer?.institutionsOverview.find(
          institution => institution.id === institutionIdFromUrl
        )

        const billing = company?.billing
        const areEmployeesSet = (billing?.employees ?? 0) > 1

        if (
          billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Active &&
          areEmployeesSet &&
          // do not ask me why we need to check, whether `modal` is undefined, but it's necessary, because `onCompleted` is called multiple times
          // it looks like a bug in Apollo Client - https://github.com/apollographql/apollo-client/issues/9338
          modal
        ) {
          trackPurchaseCompleted(billing.plan)
          setModal('success')
          stopInstitutionsPolling()
        }
      },
    })

  const isRedirectedFromChargebee = useMemo(
    () =>
      params.has(CHARGEBEE_REDIRECT_INSTITUTION_ID_PARAM_NAME) &&
      params.get('state') === 'succeeded',
    [params]
  )

  useEffect(() => {
    const isPartner = params.get(CHARGEBEE_REDIRECT_PARTNER_PARAM_NAME) === 'true'
    const institutionId = params.get(CHARGEBEE_REDIRECT_INSTITUTION_ID_PARAM_NAME)
    if (institutionId) {
      if (isPartner) {
        setInstitutionIdFromUrl(institutionId)
      } else {
        setMotherId(institutionId)
      }
    }
  }, [params, setMotherId])

  // biome-ignore lint/correctness/useExhaustiveDependencies(startInstitutionsPolling):
  // biome-ignore lint/correctness/useExhaustiveDependencies(navigate):
  useEffect(() => {
    const isPartner = params.get(CHARGEBEE_REDIRECT_PARTNER_PARAM_NAME) === 'true'

    if (isRedirectedFromChargebee && isPartner && viewer) {
      setModal('waiting')
      startInstitutionsPolling(1000)
      navigate(routes => routes.institutions(), { replace: true })
      return
    }

    if (isRedirectedFromChargebee && !isPartner && motherIdWithNull && viewer) {
      setModal('waiting')
      startPolling(1000)
      // it's not sufficient to have boolean, we need the 'I don't know' state not to redirect too soon
      // for partners it happened we redirected too soon because the default value for isPartner was false,
      // and we didn't show modal for long enough
      if (viewer?.isPartner === false) {
        if (params.get('type') === 'purchase') {
          navigate(routes => routes.dashboard(), { replace: true })
        } else {
          // don't navigate for partners otherwise we lose the params and don't display RedirectFromChargebee
          navigate(routes => routes.settingsInvoicing(), { replace: true })
        }
      }
    }
  }, [isRedirectedFromChargebee, motherIdWithNull, viewer, startPolling, params])

  useEffect(() => {
    // We do not want to continue run this hook if user is not redirected from CB
    if (!isRedirectedFromChargebee) {
      return
    }
    // it's not sufficient to have `falsey`, we need the `false` to do not redirect too soon
    // for partners it happened we redirected too soon because the default value for isPartner was false,
    // and we didn't show modal for long enough
    if (viewer?.isPartner === false) {
      if (params.get('type') === 'purchase') {
        navigate(routes => routes.dashboard(), { replace: true })
      } else {
        // don't navigate for partners otherwise we lose the params and don't display RedirectFromChargebee
        navigate(routes => routes.settingsInvoicing(), { replace: true })
      }
    }
  }, [isRedirectedFromChargebee, navigate, params, viewer?.isPartner])

  return (
    <Modal
      open={Boolean(modal)}
      footer={null}
      onCancel={() => setModal(undefined)}
      closable={false}
      maskClosable={modal === 'success'}
      centered
    >
      {modal === 'waiting' && (
        <Result
          icon={<UntitledIcon icon={ucLoader} />}
          status='info'
          title={<FormattedMessage {...messages.waitingForResponse} />}
        />
      )}
      {modal === 'success' && (
        <Result
          status='success'
          title={<FormattedMessage {...messages.success} />}
          extra={
            <Button type='primary' onClick={() => setModal(undefined)}>
              <FormattedMessage {...messages.buttonClose} />
            </Button>
          }
        />
      )}
    </Modal>
  )
}

export default RedirectFromChargebee
