import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { ulEdit05 } from '@faceup/icons/ulEdit05'
import { ulPlus } from '@faceup/icons/ulPlus'
import { ulTrash01 } from '@faceup/icons/ulTrash01'
import { useMotherId } from '@faceup/institution'
import { ucDots2Grid } from '@faceup/ui'
import {
  Alert,
  Button,
  Divider,
  Dropdown,
  Flex,
  type MenuProps,
  Skeleton,
  Space,
  Tooltip,
  Typography,
  notification,
  useModal,
} from '@faceup/ui-base'
import { type ExtractTypename, FormItemType, getTranslation, sort } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { type PropsWithChildren, useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import { formItemTypeMessages, sharedMessages } from '../../../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../../../TypedIntl'
import { graphql } from '../../../../../../__generated__'
import { Question } from '../../../../../SurveysView'
import { formItemsIcons } from '../../../Components/FormItemsListItem'
import { CreateSurveyFormItem } from './CreateSurveryFormItem'
import { EditSurveyFormItem } from './EditSurveyFormItem'

const dragListItemIdentifier = `page-form-items`

type PageFormItemsQuery = ResultOf<typeof query>
type SurveyItem = ExtractTypename<
  NonNullable<PageFormItemsQuery['survey']>['config'],
  'SurveyChannelConfiguration'
>['formItems'][number]

const messages = defineMessages({
  surveyWithSubmissionsIsNotEditable:
    'Administration.pageFormItems.surveyWithSubmissionsIsNotEditable',
  buttonCreateQuestion: 'Administration.pageFormItems.button.createQuestion',
  createSurveyTitle: 'Administration.pageFormItems.createSurvey.title',
  createSurveyDescription: 'Administration.pageFormItems.createSurvey.description',
  deleteItemTitle: 'Administration.pageFormItems.deleteItem.title',
  deleteItemContent: 'Administration.pageFormItems.deleteItem.content',
})

const query = graphql(`
  query PageFormItemsQuery(
    $reportSourceId: ReportSourceGlobalId!
    $motherId: UUID!
  ) {
    survey(reportSourceId: $reportSourceId, motherId: $motherId) {
      id
      name
      defaultLanguage
      languages
      config {
        ... on SurveyChannelConfiguration {
          id
          submissionsCount
          formItems {
            id
            order
            type
            isRequired
            labelTranslations {
              language
              translation
            }
            hintTranslations {
              language
              translation
            }
            options(includeDeleted: false) {
              id
              labelTranslations {
                language
                translation
              }
            }
            ...EditSurveyFormItem_formItem
          }
        }
      }

      ...EditSurveyFormItem_reportSource
    }
  }
`)

const mutations = {
  DeleteSurveyItem: graphql(`
    mutation DeleteSurveyItem($input: DeleteSurveyItemInput!) {
      deleteSurveyItem(input: $input) {
        config {
          id
        }
      }
    }
  `),
  ChangeSurveyFormItemOrderMutation: graphql(`
    mutation ChangeSurveyFormItemOrderMutation(
      $input: ChangeSurveyFormItemOrderInput!
    ) {
      changeSurveyFormItemOrder(input: $input) {
        survey {
          id
          config {
            ... on SurveyChannelConfiguration {
              id
              formItems {
                id
                order
              }
            }
          }
        }
      }
    }
  `),
}

const surveyFormItems = [FormItemType.Select, FormItemType.MultiSelect, FormItemType.SimpleText]

type PageFormItemsProps = {
  reportSourceId: string
}

export const PageFormItems = ({ reportSourceId }: PageFormItemsProps) => {
  const [createNewOption, setCreateNewOption] = useState<{
    type: FormItemType
    order: number
  }>()
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const { data, loading, refetch } = useQuery(query, {
    variables: { reportSourceId, motherId: getMotherId() },
  })

  const [reorderSurveyItems] = useMutation(mutations.ChangeSurveyFormItemOrderMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const config =
    data?.survey?.config?.__typename === 'SurveyChannelConfiguration'
      ? data.survey.config
      : undefined

  const sortedFormItems = sort(config?.formItems ?? [], (a, b) => (a.order ?? 0) - (b.order ?? 0))

  if (loading || !data) {
    return <Skeleton />
  }

  const survey = data.survey

  if (!config || !survey) {
    return null
  }

  const isDisabled = config.submissionsCount > 0

  if (sortedFormItems.length === 0) {
    return (
      <CreateSurveyContent>
        <CreateQuestionButton
          variant='text'
          onCreate={type => setCreateNewOption({ type, order: 0 })}
          dataTest='create-survey-question-button'
          disabled={isDisabled}
        />
        {createNewOption && (
          <CreateSurveyFormItem
            reportSourceId={reportSourceId}
            type={createNewOption.type}
            order={createNewOption.order}
            onClose={() => setCreateNewOption(undefined)}
            onSuccess={() => refetch()}
          />
        )}
      </CreateSurveyContent>
    )
  }

  return (
    <ContentWrapper>
      <Flex vertical gap='64px' className='max-w-[745px] w-full'>
        {isDisabled && (
          <Alert
            type='warning'
            message={formatMessage(messages.surveyWithSubmissionsIsNotEditable)}
          />
        )}
        <CreateSurveyFormItemWrapper dashed>
          <CreateQuestionButton
            variant='icon'
            onCreate={type => setCreateNewOption({ type, order: 0 })}
            dataTest='create-survey-question-button'
            disabled={isDisabled}
          />
        </CreateSurveyFormItemWrapper>
        <ReactDragListView
          handleSelector={`.draggable.${dragListItemIdentifier}`}
          nodeSelector={isDisabled ? undefined : `.${dragListItemIdentifier}-item`}
          onDragEnd={async (fromIndex, toIndex) => {
            // toIndex is -1 when the item is dragged outside the list
            if (toIndex < 0) {
              return
            }

            const draggedItemId = sortedFormItems[fromIndex]?.id

            await reorderSurveyItems({
              variables: {
                input: {
                  motherId: getMotherId(),
                  reportSourceId,
                  formItemId: draggedItemId ?? '',
                  newOrder: toIndex,
                },
              },
            })
          }}
          lineClassName='drag-list-line'
        >
          {sortedFormItems.map((formItem, index) => (
            <Flex
              vertical
              key={formItem.id}
              className={`${dragListItemIdentifier}-item`}
              gap='64px'
              rootClassName={index !== sortedFormItems.length - 1 ? 'mb-[64px]' : ''}
            >
              <ItemWrapper>
                {
                  <Drag
                    className={`draggable ${dragListItemIdentifier} ${isDisabled ? 'cursor-not-allowed' : 'cursor-grab'}`}
                  >
                    <UntitledIcon icon={ucDots2Grid} size={20} color='#6F91B7' />
                  </Drag>
                }

                <Flex flex='1 1 auto'>
                  {[FormItemType.SimpleText].includes(formItem.type) && (
                    <Question
                      disabled
                      title={getTranslation(
                        formItem.labelTranslations,
                        survey.defaultLanguage,
                        survey.defaultLanguage
                      )}
                      description={
                        formItem.hintTranslations
                          ? getTranslation(
                              formItem.hintTranslations,
                              survey.defaultLanguage,
                              survey.defaultLanguage
                            )
                          : undefined
                      }
                      secondary={
                        <ActionButtons
                          reportSource={survey}
                          formItem={formItem}
                          refetch={refetch}
                          disabled={isDisabled}
                        />
                      }
                      required={formItem.isRequired}
                      type={FormItemType.Select}
                      answers={[
                        {
                          id: '0',
                          title: <FillTextField />,
                          active: false,
                        },
                      ]}
                    />
                  )}
                  {[FormItemType.Select, FormItemType.MultiSelect].includes(formItem.type) && (
                    <Question
                      title={getTranslation(
                        formItem.labelTranslations,
                        survey.defaultLanguage,
                        survey.defaultLanguage
                      )}
                      description={
                        formItem.hintTranslations
                          ? getTranslation(
                              formItem.hintTranslations,
                              survey.defaultLanguage,
                              survey.defaultLanguage
                            )
                          : undefined
                      }
                      secondary={
                        <ActionButtons
                          reportSource={survey}
                          formItem={formItem}
                          refetch={refetch}
                          disabled={isDisabled}
                        />
                      }
                      required={formItem.isRequired}
                      type={FormItemType.Select}
                      answers={formItem.options.map(option => ({
                        id: option.id,
                        title: getTranslation(
                          option.labelTranslations,
                          survey.defaultLanguage,
                          survey.defaultLanguage
                        ),
                        active: false,
                      }))}
                      disabled={isDisabled}
                    />
                  )}
                </Flex>
              </ItemWrapper>
              {index !== sortedFormItems.length - 1 && (
                <CreateSurveyFormItemWrapper dashed>
                  <CreateQuestionButton
                    variant='icon'
                    onCreate={type => setCreateNewOption({ type, order: index + 1 })}
                    dataTest={`create-survey-question-button-${index + 1}`}
                    disabled={isDisabled}
                  />
                </CreateSurveyFormItemWrapper>
              )}
            </Flex>
          ))}
          {createNewOption && (
            <CreateSurveyFormItem
              reportSourceId={reportSourceId}
              type={createNewOption.type}
              order={createNewOption.order}
              onClose={() => setCreateNewOption(undefined)}
              onSuccess={() => refetch()}
            />
          )}
        </ReactDragListView>
        <CreateSurveyFormItemWrapper dashed>
          <CreateQuestionButton
            variant='icon'
            onCreate={type => setCreateNewOption({ type, order: sortedFormItems.length })}
            dataTest={`create-survey-question-button-last`}
            disabled={isDisabled}
          />
        </CreateSurveyFormItemWrapper>
      </Flex>
    </ContentWrapper>
  )
}

type ActionButtonsProps = {
  reportSource: NonNullable<PageFormItemsQuery['survey']>
  formItem: SurveyItem
  refetch: () => void
  disabled: boolean
}

const ActionButtons = ({ reportSource, formItem, refetch, disabled }: ActionButtonsProps) => {
  const modal = useModal()
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()
  const [deleteSurveyItem] = useMutation(mutations.DeleteSurveyItem, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  return (
    <ActionButtonsWrapper>
      <EditSurveyItemButton
        reportSource={reportSource}
        formItem={formItem}
        onSuccess={() => refetch()}
        disabled={disabled}
      />
      <Tooltip title={<FormattedMessage {...sharedMessages.delete} />}>
        <Button
          disabled={disabled}
          type='text'
          danger
          icon={<UntitledIcon icon={ulTrash01} />}
          size='middle'
          onClick={() =>
            modal.confirm({
              type: 'warning',
              title: formatMessage(messages.deleteItemTitle),
              content: formatMessage(messages.deleteItemContent),
              onOk: async () => {
                await deleteSurveyItem({
                  variables: {
                    input: {
                      channelId: reportSource.id,
                      formItemId: formItem.id,
                      motherId: getMotherId(),
                    },
                  },
                })
                void refetch()
              },
            })
          }
        />
      </Tooltip>
    </ActionButtonsWrapper>
  )
}

type EditSurveyItemButtonProps = {
  reportSource: NonNullable<PageFormItemsQuery['survey']>
  formItem: SurveyItem
  onSuccess: () => void
  disabled?: boolean
}

const EditSurveyItemButton = ({
  reportSource,
  formItem,
  onSuccess,
  disabled = false,
}: EditSurveyItemButtonProps) => {
  const [editing, setEditing] = useState(false)
  return (
    <Tooltip title={<FormattedMessage {...sharedMessages.edit} />}>
      <div>
        <Button
          onClick={() => setEditing(true)}
          type='text'
          icon={<UntitledIcon icon={ulEdit05} />}
          disabled={disabled}
          size='middle'
        />
        <EditSurveyFormItem
          reportSource={reportSource}
          formItem={formItem}
          onClose={() => setEditing(false)}
          opened={editing}
          onSuccess={onSuccess}
        />
      </div>
    </Tooltip>
  )
}

type CreateQuestionButtonProps = {
  variant: 'text' | 'icon'
  onCreate: (type: FormItemType) => void
  dataTest: string
  disabled?: boolean
}

const CreateQuestionButton = ({
  onCreate,
  variant,
  dataTest,
  disabled,
}: CreateQuestionButtonProps) => {
  const items: MenuProps['items'] = surveyFormItems.map(item => ({
    key: item,
    label: (
      <Space>
        <UntitledIcon icon={formItemsIcons[item]} />
        <FormattedMessage {...formItemTypeMessages[item]} />
      </Space>
    ),
    'data-test': `${dataTest}-${item}`,
  }))
  if (variant === 'icon') {
    return (
      <Dropdown menu={{ items, onClick: info => onCreate(info.key as FormItemType) }}>
        <Button
          size='middle'
          icon={<UntitledIcon icon={ulPlus} />}
          data-test={dataTest}
          disabled={disabled}
          style={{ border: disabled ? 'none' : '' }}
        />
      </Dropdown>
    )
  }
  return (
    <Dropdown menu={{ items, onClick: info => onCreate(info.key as FormItemType) }}>
      <Button ghost type='primary' icon={<UntitledIcon icon={ulPlus} />} data-test={dataTest}>
        <FormattedMessage {...messages.buttonCreateQuestion} />
      </Button>
    </Dropdown>
  )
}

type CreateSurveyContentProps = PropsWithChildren

const CreateSurveyContent = ({ children }: CreateSurveyContentProps) => (
  <VerticalCenter>
    <CreateSurveyContentWrapper>
      <CreateSurveyTextWrapper>
        <Typography.Title level={5}>
          <FormattedMessage {...messages.createSurveyTitle} />
        </Typography.Title>
        <Typography.Text type='secondary'>
          <FormattedMessage {...messages.createSurveyDescription} />
        </Typography.Text>
      </CreateSurveyTextWrapper>
      <CreateSurveyButtonWrapper>{children}</CreateSurveyButtonWrapper>
    </CreateSurveyContentWrapper>
  </VerticalCenter>
)

const CreateSurveyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const CreateSurveyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--ant-padding-xl);
`

const CreateSurveyButtonWrapper = styled.div`
  text-align: center;
`

const VerticalCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

// TODO: Remove position when buttons fixed from DS?
const Drag = styled.div`
  position: relative;
  top: -4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

const CreateSurveyFormItemWrapper = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text {
    margin: 0;
  }
`

const ActionButtonsWrapper = styled(Flex)`
  position: relative;
  top: -2px;
`

const FillTextField = styled.div`
  height: calc(100px - (16px * 2));
`
