import { Button } from '@faceup/ui-base'
import type { ContactIssue } from '@faceup/utils'
import { useState } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import ContactSalesModal from './ContactSalesModal'

const messages = defineMessages({
  contactSalesTitle: 'Administration.trialEnded.contactSales',
})

type ContactSalesButtonProps = {
  contactIssue: ContactIssue
}

export const ContactSalesButton = ({ contactIssue }: ContactSalesButtonProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  return (
    <>
      <Button
        type='primary'
        onClick={() => {
          setIsOpened(true)
        }}
      >
        <FormattedMessage {...messages.contactSalesTitle} />
      </Button>
      <ContactSalesModal
        opened={isOpened}
        onClose={() => setIsOpened(false)}
        contactIssue={contactIssue}
      />
    </>
  )
}
