import { Card, type CardProps, useTabKeyBlocker } from '@faceup/ui'
import { Overlay } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type ComponentProps, type ReactNode, createContext, useContext, useRef } from 'react'
import { UpsellIcon } from './UpsellIcon'
import { UpsellModal } from './UpsellModal'
import { type UpsellData, upsellDisabled } from './upsellData'

type UpsellCardProps = {
  upsell: UpsellData
} & CardProps

export const UpsellCard = ({ upsell, ...props }: UpsellCardProps) => {
  return (
    <UpsellCardProvider upsell={upsell}>
      <Card {...props} className={`${props.className ?? ''} relative`} />
    </UpsellCardProvider>
  )
}

const Header = ({ children, extra, ...props }: ComponentProps<typeof Card.Header>) => {
  const { upsell } = useUpsellCardProvider()
  const refExtra = useRef<HTMLDivElement>(null)
  const [opened, { open, close }] = useDisclosure(false)
  useTabKeyBlocker(upsell.showUpsell ? [refExtra] : [])

  return (
    <Card.Header
      {...props}
      extra={
        extra && (
          <div className='flex' ref={refExtra}>
            {upsell.showUpsell && <Overlay onClick={open} opacity={0} />}
            {extra}
            <UpsellModal isOpened={opened} onClose={close} upsell={upsell} />
          </div>
        )
      }
    >
      {children} {upsell.showUpsell && <UpsellIcon />}
    </Card.Header>
  )
}

const Content = ({ children, ...props }: ComponentProps<typeof Card.Content>) => {
  const { upsell } = useUpsellCardProvider()
  const [opened, { open, close }] = useDisclosure(false)
  const refContent = useRef<HTMLDivElement>(null)
  useTabKeyBlocker(upsell.showUpsell ? [refContent] : [])

  return (
    <Card.Content {...props}>
      {children && (
        <>
          {upsell.showUpsell && <Overlay onClick={open} opacity={0} />}
          <div ref={refContent}>{children}</div>
          <UpsellModal isOpened={opened} onClose={close} upsell={upsell} />
        </>
      )}
    </Card.Content>
  )
}

UpsellCard.Header = Header
UpsellCard.Content = Content

type UpsellCardContextProps = {
  upsell: UpsellData
}

type UpsellCardProviderProps = {
  children: ReactNode
} & UpsellCardContextProps

const UpsellCardContext = createContext<UpsellCardContextProps>({
  upsell: upsellDisabled,
})

const UpsellCardProvider = ({ children, upsell }: UpsellCardProviderProps) => {
  return <UpsellCardContext.Provider value={{ upsell }}>{children}</UpsellCardContext.Provider>
}

const useUpsellCardProvider = () => useContext(UpsellCardContext)
