import { ButtonGroup, Modal, type ModalProps } from '@faceup/ui'
import { Button } from '@faceup/ui-base'
import { Box } from '@mantine/core'
import { useId } from '@mantine/hooks'
import type { ReactNode } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useFormLocalization } from '../FormLocalizationProvider'
import { FormItemsWrapper } from './FormItemsWrapper'
import { type ReactHookFormProps, useFormLogic } from './useFormLogic'

// type ModalFormComponent = <FV extends FieldValues = FieldValues>(
//   props: ModalFormProps<FV>
// ) => JSX.Element
// type ComplexComponent = ModalFormComponent & ModalSubcomponents

export type ModalFormProps<FV extends FieldValues = FieldValues> = ModalProps &
  ReactHookFormProps<FV> & {
    onCancel?: () => void
    isCancelButtonHidden?: boolean
    submitUnchanged?: boolean
  }

export const ModalForm = <FV extends FieldValues = FieldValues>({
  onCancel,
  isCancelButtonHidden = false,
  ...props
}: ModalFormProps<FV>) => {
  const { modalForm } = useFormLocalization()
  const formId = useId()
  const { children, formProps, modalProps } = splitProps(props)
  const handleClose = () => {
    props.form.reset()
    modalProps.onClose?.()
  }
  const { onSubmit, submitButtonText, additionalButtons, isLoading, isSubmitButtonDisabled } =
    useFormLogic(formProps)

  return (
    <Modal
      {...modalProps}
      onClose={handleClose}
      footer={
        <ButtonGroup
          data-onboarding='modal-form-buttons'
          hasSeparateButtons
          sx={{
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          {additionalButtons}
          {!isCancelButtonHidden && (
            <Button type='default' onClick={onCancel ?? handleClose}>
              {modalForm.cancelButton}
            </Button>
          )}
          <Button
            type='primary'
            loading={isLoading}
            disabled={isSubmitButtonDisabled}
            form={formId}
            data-test='modal-form-submit-button'
            htmlType='submit'
          >
            {submitButtonText}
          </Button>
        </ButtonGroup>
      }
    >
      <Box
        id={formId}
        component='form'
        onSubmit={onSubmit}
        sx={{
          width: '100%',
        }}
      >
        <FormItemsWrapper>{children}</FormItemsWrapper>
      </Box>
    </Modal>
  )
}

// ModalForm.Segment = Modal.Segment

type FnSplitProps = <FV extends FieldValues = FieldValues>(
  props: ModalFormProps<FV>
) => {
  children?: ReactNode
  modalProps: Omit<ModalProps, 'children'>
  formProps: ReactHookFormProps<FV>
}
// Props must be separated because onSubmit in Modal made problems
const splitProps: FnSplitProps = <FV extends FieldValues = FieldValues>(
  props: ModalFormProps<FV>
) => {
  const {
    children,
    onSubmit,
    form,
    submitButtonText,
    customSubmitButtonText,
    customSubmitSuccessText,
    additionalButtons,
    isSubmitButtonDisabled,
    isSuccessMessageDisplayed,
    submitUnchanged,
    ...modalProps
  } = props
  const formProps: ReactHookFormProps<FV> = {
    onSubmit,
    form,
    submitButtonText,
    customSubmitButtonText,
    customSubmitSuccessText,
    additionalButtons,
    isSubmitButtonDisabled,
    isSuccessMessageDisplayed,
    submitUnchanged,
  } as ReactHookFormProps<FV>

  return {
    formProps,
    modalProps,
    children,
  }
}
