import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulBriefcase02 } from '@faceup/icons/ulBriefcase02'
import { ulGraduationHat02 } from '@faceup/icons/ulGraduationHat02'
import { ulUsers01 } from '@faceup/icons/ulUsers01'
import { Institution } from '@faceup/utils'
import { Avatar, Flex, Paper, Stack, Text, UnstyledButton, useMantineTheme } from '@mantine/core'
import {
  FormattedMessage,
  type TypedMessageDescriptor,
  defineMessages,
} from '../../../../TypedIntl'
import { StepHeading } from './StepHeading'

const messages = defineMessages({
  title: 'Administration.registration.fillInfo.step2.title',
  description: 'Administration.registration.fillInfo.step2.description',
})

const institutionMessages: Record<InstitutionType, Record<TypeMessages, TypedMessageDescriptor>> = {
  Partner: defineMessages<TypeMessages>({
    label: 'Administration.registration.fillInfo.step2.partner.label',
    description: 'Administration.registration.fillInfo.step2.partner.description',
  }),
  [Institution.School]: defineMessages<TypeMessages>({
    label: 'Administration.registration.fillInfo.step2.school.label',
    description: 'Administration.registration.fillInfo.step2.school.description',
  }),
  [Institution.Company]: defineMessages<TypeMessages>({
    label: 'Administration.registration.fillInfo.step2.company.label',
    description: 'Administration.registration.fillInfo.step2.company.description',
  }),
}

const institutionIcons: Record<InstitutionType, UntitledIconData> = {
  Partner: ulUsers01,
  [Institution.School]: ulGraduationHat02,
  [Institution.Company]: ulBriefcase02,
}

export type InstitutionType = Institution | 'Partner'

type TypeMessages = 'label' | 'description'

type RegistrationVariant = 'trial' | 'purchase'

const institutionsRegistration: Record<RegistrationVariant, InstitutionType[]> = {
  trial: [Institution.Company, Institution.School, 'Partner'],
  purchase: [Institution.Company, Institution.School],
}

type Step2Props = {
  variant: RegistrationVariant
  institutionType: InstitutionType
  setInstitutionType: (institutionType: InstitutionType) => void
}

export const Step2 = (props: Step2Props) => {
  const { variant, institutionType, setInstitutionType } = props
  const theme = useMantineTheme()
  const institutions = institutionsRegistration[variant]

  return (
    <>
      <StepHeading
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
      />
      {institutions.map(institution => {
        const isSelected = institution === institutionType
        return (
          <UnstyledButton
            key={institution}
            onClick={() => setInstitutionType(institution)}
            data-test={`FillInfoContent-type-${institution}`}
            mb='md'
          >
            <Paper
              sx={{
                border: `1px solid ${isSelected ? theme.colors.primary[6] : theme.colors.gray[2]}`,
              }}
            >
              <Flex px='1.438rem' py='1rem' gap='1.25rem' align='center'>
                <Avatar color='blue' variant={isSelected ? 'filled' : 'light'} size='3rem'>
                  <UntitledIcon icon={institutionIcons[institution]} />
                </Avatar>
                <Stack spacing={4}>
                  <Text fz='1rem' fw={600}>
                    <FormattedMessage {...institutionMessages[institution].label} />
                  </Text>
                  <Text c='prussianBlue.8'>
                    <FormattedMessage {...institutionMessages[institution].description} />
                  </Text>
                </Stack>
              </Flex>
            </Paper>
          </UnstyledButton>
        )
      })}
    </>
  )
}
