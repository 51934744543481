import './styles/a.css'
import './styles/alert.css'
import './styles/avatar.css'
import './styles/badge.css'
import './styles/button.css'
import './styles/collapse.css'
import './styles/checkbox.css'
import './styles/divider.css'
import './styles/form.css'
import './styles/pagination.css'
import './styles/global.css'
import './styles/list.css'
import './styles/menu.css'
import './styles/modal.css'
import './styles/message.css'
import './styles/select.css'
import './styles/switch.css'
import './styles/upload.css'
import './styles/table.css'
import './styles/tabs.css'
import './styles/tag.css'
import './styles/tooltip.css'
import './styles/typography.css'
import './styles/input.css'
import './styles/inputNumber.css'
import 'antd/dist/reset.css'
import type { DirectionType } from 'antd/es/config-provider'
import type { AliasToken } from 'antd/es/theme/internal'
import { shade, tint } from 'polished'
import { type PropsWithChildren, createContext, useContext } from 'react'
import { ConfigProvider, type Locale } from './'

type UiBaseProviderProps = PropsWithChildren<{
  locale: Locale | undefined
  colorPrimary?: string
  colorDark?: string
  direction: DirectionType
}>
type CustomTokensPrimary = Record<
  | 'primary1'
  | 'primary2'
  | 'primary3'
  | 'primary4'
  | 'primary5'
  | 'primary6'
  | 'primary7'
  | 'primary8'
  | 'primary9'
  | 'primary10',
  string
>
type TokensBlue = Pick<
  AliasToken,
  'blue1' | 'blue2' | 'blue3' | 'blue4' | 'blue5' | 'blue6' | 'blue7' | 'blue8' | 'blue9' | 'blue10'
>
type TokensGreen = Pick<
  AliasToken,
  | 'green1'
  | 'green2'
  | 'green3'
  | 'green4'
  | 'green5'
  | 'green6'
  | 'green7'
  | 'green8'
  | 'green9'
  | 'green10'
>
type TokensRed = Pick<
  AliasToken,
  'red1' | 'red2' | 'red3' | 'red4' | 'red5' | 'red6' | 'red7' | 'red8' | 'red9' | 'red10'
>

type TokensPurple = Pick<
  AliasToken,
  | 'purple1'
  | 'purple2'
  | 'purple3'
  | 'purple4'
  | 'purple5'
  | 'purple6'
  | 'purple7'
  | 'purple8'
  | 'purple9'
  | 'purple10'
>
type TokensPrimary = Pick<
  AliasToken,
  | 'colorPrimary'
  | 'colorPrimaryHover'
  | 'colorPrimaryActive'
  | 'colorPrimaryBg'
  | 'colorPrimaryBgHover'
  | 'colorPrimaryBorder'
  | 'colorPrimaryBorderHover'
  | 'colorPrimaryText'
  | 'colorPrimaryTextHover'
  | 'colorPrimaryTextActive'
>
type TokensBorder = Pick<AliasToken, 'colorBorder'>
type TokensLink = Pick<AliasToken, 'colorLink' | 'colorLinkHover' | 'colorLinkActive'>
type TokensText = Pick<
  AliasToken,
  | 'colorText'
  | 'colorTextSecondary'
  | 'colorTextTertiary'
  | 'colorTextQuaternary'
  | 'colorTextLightSolid'
>
type TokensTextExtra = Pick<
  AliasToken,
  | 'colorTextHeading'
  | 'colorTextLabel'
  | 'colorTextDescription'
  | 'colorTextDisabled'
  | 'colorTextPlaceholder'
>
type TokensBorderRadius = Pick<
  AliasToken,
  'borderRadiusXS' | 'borderRadiusSM' | 'borderRadius' | 'borderRadiusLG'
>
type TokensError = Pick<
  AliasToken,
  | 'colorError'
  | 'colorErrorActive'
  | 'colorErrorBg'
  | 'colorErrorBgActive'
  | 'colorErrorBgFilledHover'
  | 'colorErrorHover'
  | 'colorErrorBorderHover'
>
type CustomColors = TokensRed & TokensGreen & TokensPurple & TokensBlue & CustomTokensPrimary
type Tokens = TokensGreen &
  TokensRed &
  TokensPurple &
  TokensBlue &
  TokensPrimary &
  TokensLink &
  TokensText &
  TokensTextExtra &
  TokensBorderRadius &
  TokensBorder &
  TokensError &
  Pick<AliasToken, 'fontFamily' | 'colorBorderSecondary' | 'colorBgSpotlight'>

export const UiBaseProvider = ({
  colorPrimary,
  colorDark,
  locale,
  children,
}: UiBaseProviderProps) => {
  const blue: TokensBlue = {
    blue1: '#E6F3FF',
    blue2: '#B9DDFF',
    blue3: '#71BBFF',
    blue4: '#48A7FF',
    blue5: '#0085FF',
    blue6: '#007AE1',
    blue7: '#0070CD',
    blue8: '#0061B1',
    blue9: '#005296',
    blue10: '#003A68',
  }
  const customPrimary: CustomTokensPrimary = {
    primary1: colorPrimary ? tint(0.9, colorPrimary) : blue.blue1, // původně 0.45
    primary2: colorPrimary ? tint(0.72, colorPrimary) : blue.blue2, // původně 0.36
    primary3: colorPrimary ? tint(0.44, colorPrimary) : blue.blue3, // původně 0.22
    primary4: colorPrimary ? tint(0.28, colorPrimary) : blue.blue4, // původně 0.14
    primary5: colorPrimary ? colorPrimary : blue.blue5, // beze změn
    primary6: colorPrimary ? shade(0.12, colorPrimary) : blue.blue6, // původně 0.06
    primary7: colorPrimary ? shade(0.2, colorPrimary) : blue.blue7, // původně 0.10
    primary8: colorPrimary ? shade(0.3, colorPrimary) : blue.blue8, // původně 0.15
    primary9: colorPrimary ? shade(0.42, colorPrimary) : blue.blue9, // původně 0.21
    primary10: colorPrimary ? shade(0.6, colorPrimary) : blue.blue10, // původně 0.30
  }
  const green: TokensGreen = {
    green1: '#EBF9F2',
    green2: '#D6F3E5',
    green3: '#ADE7CA',
    green4: '#84DAB0',
    green5: '#32C27B',
    green6: '#2CA86A',
    green7: '#269A60',
    green8: '#1F8954',
    green9: '#187546',
    green10: '#115E37',
  }
  const red: TokensRed = {
    red1: '#FEECF1',
    red2: '#FDD9E2',
    red3: '#FBB2C6',
    red4: '#F98CA9',
    red5: '#F54070',
    red6: '#E42457',
    red7: '#D01748',
    red8: '#BB0E3C',
    red9: '#A50731',
    red10: '#7D0726',
  }
  const purple: TokensPurple = {
    purple1: '#F5EEFC',
    purple2: '#EBDDFA',
    purple3: '#D7BAF5',
    purple4: '#C498EF',
    purple5: '#9C53E5',
    purple6: '#8E4CD1',
    purple7: '#7C34C4',
    purple8: '#6821B0',
    purple9: '#5A179E',
    purple10: '#480F82',
  }
  const error: TokensError = {
    colorError: red.red5,
    colorErrorBg: red.red1,
    colorErrorHover: red.red6,
    colorErrorBgFilledHover: red.red6,
    colorErrorBorderHover: red.red6,
    colorErrorActive: red.red7,
    colorErrorBgActive: red.red7,
  }
  const primary: TokensPrimary = {
    colorPrimary: customPrimary.primary5,
    colorPrimaryHover: customPrimary.primary6,
    colorPrimaryActive: customPrimary.primary7,
    colorPrimaryBg: customPrimary.primary1,
    colorPrimaryBgHover: customPrimary.primary2,
    colorPrimaryBorder: customPrimary.primary3,
    colorPrimaryBorderHover: customPrimary.primary4,
    colorPrimaryText: customPrimary.primary5,
    colorPrimaryTextHover: customPrimary.primary6,
    colorPrimaryTextActive: customPrimary.primary7,
  }

  const defaultTokens: TokensBorder = {
    colorBorder: '#CDDDEE',
  }

  const link: TokensLink = {
    colorLink: primary.colorPrimary,
    colorLinkHover: primary.colorPrimaryHover,
    colorLinkActive: primary.colorPrimaryActive,
  }
  const text: TokensText = {
    colorText: '#062D46',
    colorTextSecondary: '#6F91B7',
    colorTextTertiary: '#547599',
    colorTextQuaternary: '#92B4DB',
    colorTextLightSolid: '#FFFFFF',
  }
  const textExtra: TokensTextExtra = {
    colorTextHeading: text.colorText,
    colorTextLabel: text.colorTextSecondary,
    // this $#%&* is used as secondary text color
    colorTextDescription: text.colorTextSecondary,
    colorTextDisabled: text.colorTextQuaternary,
    colorTextPlaceholder: text.colorTextQuaternary,
  }
  const borderRadius: TokensBorderRadius = {
    borderRadiusXS: 4,
    borderRadiusSM: 6,
    borderRadius: 8,
    borderRadiusLG: 12,
  }
  const token: Tokens = {
    fontFamily: 'Inter, sans-serif',
    colorBorderSecondary: '#ECF0FA',
    ...primary,
    ...green,
    ...red,
    ...purple,
    ...blue,
    ...link,
    ...text,
    ...textExtra,
    ...borderRadius,
    ...error,
    ...defaultTokens,

    colorLink: customPrimary.primary5,
    colorLinkHover: customPrimary.primary6,
    colorLinkActive: customPrimary.primary7,
    colorBgSpotlight: '#062D46',
  }

  const customColors: CustomColors = {
    ...red,
    ...green,
    ...purple,
    ...blue,
    ...customPrimary,
  }

  return (
    <UiBaseInnerProvider token={token} colors={customColors}>
      <ConfigProvider
        componentSize='large'
        locale={locale}
        theme={{
          cssVar: false,
          hashed: false,
          token,
          components: {
            Alert: {
              colorWarningBg: '#FEF4ED',
              colorWarningBorder: '#FDD3B7',
              colorWarning: '#FA914B',
            },
            Typography: {
              fontSizeHeading1: 32,
              fontSizeHeading2: 28,
              fontSizeHeading3: 24,
              fontSizeHeading4: 20,
              fontSizeHeading5: 16,
              titleMarginBottom: 0,
              titleMarginTop: 0,
              lineHeight: 1.6,
              lineHeightHeading1: 1.2,
              lineHeightHeading2: 1.2,
              lineHeightHeading3: 1.2,
              lineHeightHeading4: 1.2,
              lineHeightHeading5: 1.2,
            },
            Badge: {
              textFontWeight: 'semibold',
            },
            Menu: {
              darkItemBg: colorDark ?? '#062D46',
              darkItemSelectedBg: '#5A7A9A40',
              darkPopupBg: colorDark ?? '#062D46',
              darkItemColor: '#6F91B7',
            },
            Tabs: {
              horizontalMargin: '0',
              itemColor: '#547599',
            },
            Form: {
              labelColor: token.colorTextLabel,
              marginLG: 16,
            },
            Input: {
              inputFontSizeSM: 14,
              inputFontSize: 14,
              inputFontSizeLG: 14,
              paddingBlockLG: 10.5,
              borderRadiusSM: borderRadius.borderRadiusSM,
              borderRadius: borderRadius.borderRadius,
              borderRadiusLG: borderRadius.borderRadiusLG,
            },
            Select: {
              singleItemHeightLG: 44,
              multipleItemHeightLG: 36,
              fontSizeLG: 14,
              multipleItemBg: token.blue1,
              colorIcon: token.blue5,
              optionActiveBg: token.blue1,
              optionSelectedBg: token.blue1,
            },
            List: {
              itemPaddingLG: '12px 0',
            },
            DatePicker: {
              fontSizeLG: 14,
            },
            InputNumber: {
              borderRadius: borderRadius.borderRadiusLG,
              borderRadiusLG: borderRadius.borderRadiusLG,
              controlHeight: 32,
              controlHeightLG: 44,
              controlHeightSM: 24,
            },
            Button: {
              fontSizeLG: 14,
              fontWeight: 700,
              colorPrimaryText: primary.colorPrimaryText,
              colorPrimaryHover: primary.colorPrimaryHover,
              colorPrimaryActive: primary.colorPrimaryActive,
              colorBorder: defaultTokens.colorBorder,
              colorBgContainerDisabled: '#9DB0DF14',
              dangerShadow: 'none',
              borderColorDisabled: '#ffffffff',
              textHoverBg: '#ffffffff',
              colorBgTextActive: '#ffffffff',
              textTextActiveColor: primary.colorPrimaryActive,
              textTextHoverColor: primary.colorPrimaryHover,
            },
            Switch: {
              handleShadow: 'none',
            },
            Tag: {
              ...blue,
              ...green,
              ...red,
              ...purple,
              defaultBg: '#9DB0DF26',
            },
            Modal: {
              colorBgMask: '#062D46B2',
              borderRadiusLG: 20,
            },
            Dropdown: {
              controlItemBgHover: '#9DB0DF26',
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </UiBaseInnerProvider>
  )
}

type UiBaseContextProps = {
  token: Tokens
  colors: CustomColors
}

type UiBaseInnerProviderProps = PropsWithChildren<UiBaseContextProps>

const UiBaseContext = createContext<UiBaseContextProps>({
  token: {} as Tokens,
  colors: {} as CustomColors,
})

const UiBaseInnerProvider = ({ children, ...props }: UiBaseInnerProviderProps) => (
  <UiBaseContext.Provider value={props}>{children}</UiBaseContext.Provider>
)

export const useUiBaseConfig = () => useContext(UiBaseContext)
