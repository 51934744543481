import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulDotsHorizontal } from '@faceup/icons/ulDotsHorizontal'
import { Link, type RouteCallback } from '@faceup/router'
import { Badge, Dropdown, Menu, Space } from '@faceup/ui-base'
import { useElementSize } from '@mantine/hooks'
import type { ComponentProps, ReactNode } from 'react'
import { UserAvatar, type UserAvatarProps } from '../UserAvatar'
import styles from './assets/AdminMenu.module.css'

export type AdminMenuItem = {
  // Key is also used for generating data-onboarding attribute
  key: string
  link: RouteCallback
  label: string
  icon: UntitledIconData
  visible?: boolean
  badgeValue?: number
  badgeProps?: { 'data-test'?: string }
}

type UseMenuItemGlobal = {
  visible?: boolean
}

type UserMenuItemButton = {
  type: 'button'
  icon: UntitledIconData
  value: ReactNode
  onClick?: () => void
} & UseMenuItemGlobal

type UserMenuItemTitle = {
  type: 'title'
  value: ReactNode
} & UseMenuItemGlobal

type UserMenuItemDivider = {
  type: 'divider'
} & UseMenuItemGlobal

export type UserMenu = {
  data: UserAvatarProps['user']
  menu?: UserMenuItem[]
}

export type UserMenuItem = UserMenuItemButton | UserMenuItemTitle | UserMenuItemDivider

type AdminMenuProps = {
  logo: string
  homepageLink: RouteCallback
  items: AdminMenuItem[]
  activeKey: string
  logoBackground?: string
  user?: UserMenu
  additionalItems?: ReactNode
}

export const AdminMenu = ({
  items: _items,
  user,
  activeKey: _activeKey,
  additionalItems,
  logo,
  homepageLink,
  logoBackground = 'var(--ant-color-primary)',
}: AdminMenuProps) => {
  const { ref: menuRef, items, activeKey } = useMenuItems({ items: _items, activeKey: _activeKey })

  return (
    <div className={styles.flexWrapper}>
      <div className={styles.flexInner}>
        <div className={styles.logoWrapper}>
          <Link
            to={homepageLink}
            style={{
              backgroundColor: logoBackground,
            }}
          >
            <img src={logo} alt='Logo' />
          </Link>
        </div>
        <div ref={menuRef} className={styles.menu}>
          <Menu
            mode='inline'
            theme='dark'
            selectedKeys={[activeKey]}
            items={items}
            triggerSubMenuAction='click'
          />
        </div>
      </div>
      <Space className={styles.bottomMenu} direction='vertical'>
        {additionalItems}
        {user && (
          <Dropdown
            // @ts-expect-error Damn you ant design
            placement='rightBottom'
            overlayStyle={{
              position: 'fixed',
            }}
            disabled={!user.menu}
            dropdownRender={menu => <div className={styles.dropdownItem}>{menu}</div>}
            menu={{
              items: user.menu?.map((item, key) => {
                if (item.visible === false) {
                  return null
                }
                switch (item.type) {
                  case 'button':
                    return {
                      key,
                      label: item.value,
                      onClick: () => {
                        item?.onClick?.()
                      },
                      title: '',
                      icon: <UntitledIcon icon={item.icon} />,
                      type: 'item',
                    }
                  case 'title':
                    return { key, label: item.value, type: 'group', style: { fontWeight: 600 } }
                  case 'divider':
                    return { type: 'divider' }
                  default:
                    return null
                }
              }),
            }}
          >
            <UserAvatar className={styles.userAvatar} user={user.data} size={40} />
          </Dropdown>
        )}
      </Space>
    </div>
  )
}

const keyMore = 'more'
const itemHeight = 48 + 8

type UseMenuItemsProps = {
  items: AdminMenuItem[]
  activeKey: string
}

type UseMenuItemsReturn = {
  ref: React.RefObject<HTMLDivElement>
  items: ComponentProps<typeof Menu>['items']
  activeKey: string
}

const useMenuItems = ({ items, activeKey }: UseMenuItemsProps): UseMenuItemsReturn => {
  const { ref, height: menuHeight } = useElementSize()
  // Count how many menu items are fully visible in menuHeight
  const maxVisibleItems = Math.floor(menuHeight / itemHeight)

  const filteredItems: ComponentProps<typeof Menu>['items'] = items
    .filter(item => item.visible || item.visible === undefined)
    .map(item => ({
      'data-onboarding': `menu-${item.key}`,
      key: item.key,
      icon: (
        <Badge
          {...item.badgeProps}
          color='error'
          count={item.badgeValue}
          offset={[0, 0]}
          overflowCount={9}
          size='small'
        >
          <UntitledIcon className={styles.menuItemIcon} icon={item.icon} />
        </Badge>
      ),
      label: (
        <Link to={item.link} data-test={`main-menu-${item.key}`}>
          {item.label}
        </Link>
      ),
    }))

  const areAllItemsVisible = filteredItems.length <= maxVisibleItems
  const visibleItems = areAllItemsVisible
    ? filteredItems
    : filteredItems.slice(0, maxVisibleItems - 1)
  const hiddenItems = areAllItemsVisible ? [] : filteredItems.slice(maxVisibleItems - 1)

  if (!areAllItemsVisible) {
    visibleItems.push({
      key: keyMore,
      icon: <UntitledIcon icon={ulDotsHorizontal} />,
      children: hiddenItems,
    })
  }

  if (menuHeight === 0) {
    return {
      ref,
      items: [],
      activeKey: '',
    }
  }

  return {
    ref,
    items: visibleItems,
    activeKey,
  }
}
