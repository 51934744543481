import { UntitledIcon } from '@faceup/icons'
import { ulEye } from '@faceup/icons/ulEye'
import { ulEyeOff } from '@faceup/icons/ulEyeOff'
import { UserAvatar } from '@faceup/ui'
import { Avatar } from '@faceup/ui-base'
import { UnstyledButton } from '@mantine/core'
import type { ReactNode } from 'react'
import { type RedactTextType, useRedaction } from './RedactionProvider'
import { getText } from './TextToRedact'

type UsernameAvatarRedactProps = {
  children: ReactNode
  type: RedactTextType
  text: string
}

export const UsernameAvatarRedact = (props: UsernameAvatarRedactProps) => {
  const { children, type, text: originalText } = props
  const { isRedactionActive, texts, redactText } = useRedaction()

  if (!isRedactionActive) {
    return <>{children}</>
  }

  const redactedText = getText(texts, type)
  const text = redactedText ?? originalText

  if (type.type === 'sender') {
    return (
      <UserAvatar
        user={{
          name: text,
        }}
      />
    )
  }

  const shownName = text === originalText ? text : `User ${text}`

  return (
    <UnstyledButton
      sx={{
        display: 'flex',
        height: 'fit-content',
        '& .ant-avatar:first-of-type': {
          display: 'none',
        },
        '&:hover': {
          '& .ant-avatar:first-of-type': {
            display: 'flex',
          },
          '& .ant-avatar:last-of-type': {
            display: 'none',
          },
        },
      }}
      onClick={() => {
        redactText(
          type,
          {
            start: 0,
            end: text.length,
            variant: redactedText === undefined ? 'redacted' : 'notRedacted',
          },
          originalText
        )
      }}
    >
      <Avatar src={<UntitledIcon icon={text === originalText ? ulEyeOff : ulEye} />} />
      <UserAvatar
        user={{
          name: shownName,
        }}
      />
    </UnstyledButton>
  )
}
