import { UntitledIcon } from '@faceup/icons'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Tag as AntTag, type TagProps as AntTagProps } from 'antd'

export type TagProps = AntTagProps

/**
 *
 * Due to high amount of tags without border, the default value is changed to false.
 *
 * Dashed border in Figma is not implemented yet, we probably do not use it
 */
export const Tag = ({ closeIcon, ...props }: TagProps) => {
  return (
    <AntTag
      {...props}
      bordered={props.bordered ?? false}
      closable={props.closable ?? false}
      closeIcon={closeIcon ?? <UntitledIcon icon={ulXClose} />}
    />
  )
}
