import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { Tooltip, type TooltipProps } from '@faceup/ui-base'
import { Box } from '@mantine/core'

export type HintIconProps = {
  variant?: 'normal' | 'heading'
} & Omit<TooltipProps, 'children'>

export const HintIcon = ({ variant, ...props }: HintIconProps) => (
  <Tooltip {...props}>
    <Box
      component={UntitledIcon}
      icon={ulInfoCircle}
      sx={
        variant === 'heading'
          ? {
              color: 'prussianBlue.6',
              fontSize: 16,
            }
          : {}
      }
    />
  </Tooltip>
)
