import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import { defineMessages } from '../../TypedIntl'
import logoFu from '../../assets/logo-fu.png'
import logoNntb from '../../assets/logo-nntb.png'
import { ThemeType, getTheme } from '../../locales'
import stopImage from './assets/stop.png'

const theme = getTheme()

export const _messages = defineMessages({
  storeDocumentSubtitle: 'Administration.settings.recovery.pdf.subtitle',
  createdOn: 'Shared.global.created',
  recoveryKeyTitle: 'Administration.settings.recovery.title',
  pdfDescription: 'Administration.settings.recovery.pdf.description',
  doNotShareWarning: 'Shared.pdf.doNotShare',
})

Font.register({
  family: 'Inter',
  fonts: [
    { src: '/fonts/inter-regular.ttf', fontStyle: 'normal', fontWeight: 400 },
    { src: '/fonts/inter-semibold.ttf', fontStyle: 'normal', fontWeight: 600 },
  ],
})

// ! context isn't reachable at this point -> messages need to be imported
type RecoveryKeyProps = {
  recoveryKey: string
  name: string
  createdAt: Date
  messages: Record<keyof typeof _messages, string>
}
const PDFRecoveryKey = ({ recoveryKey, name, createdAt, messages }: RecoveryKeyProps) => {
  return (
    <Document creator={theme} producer={theme}>
      <Page
        size='A4'
        style={{
          padding: '60px 36px',
          color: '#062d46',
          fontSize: 14,
          lineHeight: 1.4,
          fontFamily: 'Inter',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 52,
          }}
        >
          <Image
            style={{
              width: theme === ThemeType.NNTB ? 88 : 110,
              height: theme === ThemeType.NNTB ? 38 : 30,
              padding: 2,
            }}
            src={theme === ThemeType.NNTB ? logoNntb : logoFu}
          />
          <Text style={{ fontSize: 12 }}>
            {messages.createdOn} {moment(createdAt).format('l')}
          </Text>
        </View>
        <View>
          <Text style={{ marginBottom: 24, fontSize: 20, fontFamily: 'Inter', fontWeight: 600 }}>
            {messages.recoveryKeyTitle} - {name}
          </Text>
          <Text style={{ marginBottom: 16, fontFamily: 'Inter', fontWeight: 600 }}>
            {messages.storeDocumentSubtitle}
          </Text>
          <Text style={{ marginBottom: 16 }}>{messages.pdfDescription}</Text>
        </View>
        <View
          style={{
            backgroundColor: '#ebeef0',
            borderRadius: 8,
            padding: '14px 24px 20px',
            textAlign: 'center',
            marginTop: 24,
          }}
        >
          <Text style={{ marginBottom: 10, fontFamily: 'Inter', fontWeight: 600 }}>
            {messages.recoveryKeyTitle}
          </Text>
          <Text
            style={{
              backgroundColor: '#fff',
              borderRadius: 6,
              padding: '8px 12px',
              fontSize: 20,
              fontFamily: 'Inter',
              fontWeight: 600,
            }}
          >
            {recoveryKey}
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            fontSize: 14,
            position: 'absolute',
            bottom: 36,
            left: 36,
            fontFamily: 'Inter',
            fontWeight: 600,
          }}
        >
          <Image style={{ marginRight: 12, padding: 2, width: 26, height: 26 }} src={stopImage} />
          <Text style={{ marginTop: 3 }}>{messages.doNotShareWarning}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default PDFRecoveryKey
