import type { ReactNode } from 'react'
import { OverTheTopSection } from './OverTheTopSection'
import styles from './TabPageLayout.module.css'

type TabPageLayoutProps = {
  children: ReactNode
}

export const TabPageLayout = ({ children }: TabPageLayoutProps) => {
  return <div className={styles.root}>{children}</div>
}

TabPageLayout.OverTheTopSection = OverTheTopSection
