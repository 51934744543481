import './assets/input-phone.css'
import { constants, ConfigContext, SizeContext } from '@faceup/ui-base'
import type { Country } from '@faceup/utils'
import { useContext } from 'react'
import ReactPhoneInput, { type PhoneInputProps } from 'react-phone-input-2'
import { UiContext } from '../UiProvider/UiProvider'

// @ts-expect-error Hack due https://github.com/vitejs/vite/issues/4704
const PhoneInput: typeof ReactPhoneInput = ReactPhoneInput.default
  ? // @ts-expect-error Hack due https://github.com/vitejs/vite/issues/4704
    ReactPhoneInput.default
  : ReactPhoneInput

type InputPhoneProps = {
  country?: Country | null
  'data-test'?: string
} & Omit<
  PhoneInputProps,
  | 'inputClass'
  | 'enableSearch'
  | 'searchPlaceholder'
  | 'localization'
  | 'disableSearchIcon'
  | 'country'
>

const InputPhone = ({ country, ...props }: InputPhoneProps) => {
  const { inputPhone } = useContext(UiContext)
  const { theme } = useContext(ConfigContext)
  const size = useContext(SizeContext)

  return (
    <PhoneInput
      {...inputPhone}
      {...props}
      inputProps={{
        'data-test': props['data-test'],
      }}
      country={country?.toLowerCase() ?? undefined}
      inputClass={`ant-input ant-input-outlined ${
        typeof theme?.cssVar === 'object' ? theme.cssVar.key : ''
      } ant-input-css-var ${constants.input.sizeClass[size ?? 'middle']}`}
      enableSearch
      disableSearchIcon
      onChange={(value, data, event, formattedValue) =>
        props?.onChange?.(value && `+${value}`, data, event, formattedValue)
      }
    />
  )
}

export default InputPhone
