import { UntitledIcon } from '@faceup/icons'
import { ulRefreshCw01 } from '@faceup/icons/ulRefreshCw01'
import { useRouteError } from '@faceup/router'
import { useSessionStorage } from '@faceup/ui'
import { Button, Typography } from '@faceup/ui-base'
import { Avatar, Center, Stack } from '@mantine/core'
import { useEffect } from 'react'
import { version } from '../../package.json'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  title: 'Administration.appErrorBoundary.title',
  reload: 'Administration.appErrorBoundary.reload',
})

const { Title } = Typography

export const AppErrorBoundary = () => {
  useAutoReloadIfNewVersionAvailable()

  return (
    <Center h='100%' sx={{ flex: 1 }}>
      <Stack align='center' maw={400} p={24} ta='center'>
        <Avatar color='orange' size={80}>
          <UntitledIcon icon={ulRefreshCw01} />
        </Avatar>
        <Title level={1}>500</Title>
        <FormattedMessage {...messages.title} />
        <Button type='primary' ghost onClick={() => window.location.reload()}>
          <FormattedMessage {...messages.reload} />
        </Button>
      </Stack>
    </Center>
  )
}

const useAutoReloadIfNewVersionAvailable = () => {
  const error = useRouteError()
  const [lastLoadedVersion, setLastLoadedVersion] = useSessionStorage<string, string | null>(
    'lastLoadedVersion',
    null
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies(error):
  useEffect(() => {
    const isNewVersion = async () => {
      const response = await fetch('/version.json')
      // We don't care if failed, we just don't reload
      if (!response.ok) {
        return
      }
      const data = await response.json()
      const newVersion = data.version
      const isNewVersionAvailable = newVersion !== version
      const wasLoadedVersionReloadedWithNoUpdate = lastLoadedVersion === version
      if (isNewVersionAvailable && !wasLoadedVersionReloadedWithNoUpdate) {
        setLastLoadedVersion(version)
        window.location.reload()
      }
    }
    const shouldSkipCheck =
      import.meta.env.VITE_ENVIRONMENT === 'development' ||
      import.meta.env.VITE_ENVIRONMENT === 'preview'
    if (!shouldSkipCheck) {
      void isNewVersion()
    }
  }, [lastLoadedVersion, setLastLoadedVersion, error])
}
