import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { usAttachment01 } from '@faceup/icons/usAttachment01'
import { Button, Flex, Form, Space } from '@faceup/ui-base'
import { ActionIcon } from '@mantine/core'
import type { ReactNode } from 'react'
import { InputFake } from '../Input'
import { ucDropzone } from '../customIcons'
import Dropzone, { type DropzoneProps } from './Dropzone'

type ModifiedDropzoneProps = Omit<DropzoneProps, 'style' | 'children' | 'translations'>

type ChatDropzoneProps = {
  children: ReactNode
  suffix?: ReactNode
  prefix?: ReactNode
  translations: {
    title: string
    add: string
  } & DropzoneProps['translations']
} & ModifiedDropzoneProps

const ChatDropzone = (props: ChatDropzoneProps) => (
  <Dropzone
    style={{ width: '100%' }}
    translations={props.translations}
    disabled={props.disabled}
    attachments={props.attachments}
    setAttachments={props.setAttachments}
    alreadyUploadedAttachments={props.alreadyUploadedAttachments}
    markAttachmentForDeletion={props.markAttachmentForDeletion}
  >
    {(open, isMobile) => (
      <>
        <InputFake
          value={props.children}
          suffix={
            <Flex style={{ pointerEvents: props.disabled ? 'none' : 'all' }}>{props.suffix}</Flex>
          }
          prefix={
            !isMobile ? (
              <Flex style={{ pointerEvents: props.disabled ? 'none' : 'all' }}>
                <ActionIcon onClick={() => !props.disabled && open()} size='lg'>
                  <UntitledIcon icon={usAttachment01} />
                </ActionIcon>
                <Space style={{ marginLeft: '-10px' }} size={0}>
                  {props.prefix}
                </Space>
              </Flex>
            ) : (
              <Flex style={{ pointerEvents: props.disabled ? 'none' : 'all' }}>{props.prefix}</Flex>
            )
          }
          variant='textarea'
        />

        {isMobile && (
          <Form.Item label={props.translations.title} style={{ paddingTop: '1rem' }}>
            <Button onClick={open} size='middle' disabled={props.disabled}>
              <Space align='center'>
                <UploadIcon icon={ucDropzone} />
                {props.translations.add}
              </Space>
            </Button>
          </Form.Item>
        )}
      </>
    )}
  </Dropzone>
)

const UploadIcon = styled(UntitledIcon)`
  font-size: 1rem;
  display: flex;
  color: white;
`

export default ChatDropzone
