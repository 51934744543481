import { CaseStatus, Day, type ExtractTypename } from '@faceup/utils'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import type { CompanyReportFollowUpChatQuery } from './ChatWrapper'
import { ChatPopupAlertType, PopupsContext } from './PopupsContext'

type CompanyReport = ExtractTypename<CompanyReportFollowUpChatQuery['report'], 'CompanyReport'>

type Props = {
  children: React.ReactNode
  report: CompanyReport | null
}

const WORKING_HOURS_DATE_FORMAT = 'HH:mm'

const PopupsProvider = ({ children, report }: Props) => {
  const [alertType, setAlertType] = useState<ChatPopupAlertType | null>(null)

  useEffect(() => {
    const reportHasVictim = Boolean(report?.victim?.id)
    if (!reportHasVictim) {
      return setAlertType(ChatPopupAlertType.NotVictimReport)
    }

    if (report?.status !== CaseStatus.Open) {
      return setAlertType(ChatPopupAlertType.ReportStatus)
    }

    checkWorkingHours()
  }, [report])

  const convertIsoWeekdayIndexToDay = (isoWeekday: number): Day | null => {
    switch (isoWeekday) {
      case 1:
        return Day.Monday
      case 2:
        return Day.Tuesday
      case 3:
        return Day.Wednesday
      case 4:
        return Day.Thursday
      case 5:
        return Day.Friday
      case 6:
        return Day.Saturday
      case 7:
        return Day.Sunday
      default:
        return null
    }
  }

  const checkWorkingHours = () => {
    const workingHours = report?.company?.workingHours
    if (workingHours?.enabled && workingHours?.timezone) {
      const weekday = convertIsoWeekdayIndexToDay(moment().isoWeekday())
      const daySettings = workingHours?.days?.find(day => day.name === weekday)

      if (!daySettings) {
        return setAlertType(ChatPopupAlertType.OutsideWorkingHours)
      }

      const from = moment(daySettings.from, WORKING_HOURS_DATE_FORMAT)
      const to = moment(daySettings.to, WORKING_HOURS_DATE_FORMAT)

      if (!moment().tz(workingHours?.timezone).isBetween(from, to)) {
        return setAlertType(ChatPopupAlertType.OutsideWorkingHours)
      }
    }
    return setAlertType(null)
  }

  return (
    <PopupsContext.Provider
      value={{
        alertType,
        closeNotOpenReportAlert: () => setAlertType(null),
      }}
    >
      {children}
    </PopupsContext.Provider>
  )
}

export default PopupsProvider
