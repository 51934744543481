import { type ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import {
  getCurrentEncryptionVersion,
  processCredentialsChange,
  savePersonalKeys,
} from '@faceup/crypto'
import { useCryptoErrorHandler } from '@faceup/report'
import { useParams } from '@faceup/router'
import { Button, Form, Input, Typography, notification, useModal } from '@faceup/ui-base'
import {
  TokenType,
  USER_NAME_REGEX,
  USER_PASSWORD_MIN_LENGTH,
  isPasswordValid,
} from '@faceup/utils'
import { useEffect, useState } from 'react'
import PasswordInputWithPopover, { PasswordInputWithIcon } from '../../Components/PasswordInput'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import useConfigForProject from '../../hooks/useConfigForProject'
import useAnalytics from '../../utils/analytics'
import Auth from '../../utils/auth'
import useRegion from '../../utils/useRegion'
import PageTemplateUnlogged from '../PageTemplateUnlogged'
import Recovery from './Recovery'

const { Title: AntTitle } = Typography

const messages = defineMessages({
  createPasswordTitle: 'Administration.createPassword.createPassword.title',
  forgottenPasswordTitle: 'Administration.createPassword.forgottenPassword.title',
  migrateToSsoTitle: 'Administration.createPassword.migrateToSso.title',
  migrateFromSsoTitle: 'Administration.createPassword.migrateFromSso.title',
  nameLabel: 'Administration.createPassword.name.label',
  password2Label: 'Administration.password.confirm.label',
  tokenErrorTitle: 'Administration.createPassword.token.error.title',
  tokenErrorDescription: 'Administration.createPassword.token.error.description',
  description: 'Administration.createPassword.description',
  login: 'Administration.createPassword.login',
  createAccountSubmit: 'Administration.createPassword.createPassword.submit',
  recoveryLabel: 'Administration.createPassword.recovery.label',
  recoveryError: 'Administration.createPassword.recovery.error',
  recoveryUpload: 'Administration.createPassword.recovery.upload',
  recoveryUploaded: 'Administration.createPassword.recovery.uploaded',
  codeLabel: 'Administration.login.2FA.label',
  codeError: 'Administration.login.2FA.error',
})

const mutation = {
  editPassword: graphql(`
    mutation EditPasswordLoginMutation($input: UserTokenPasswordInput!) {
      userTokenPassword(input: $input) {
        token
        userType
      }
    }
  `),
  migrateToSSO: graphql(`
    mutation MigrateToSSOMutation($input: MigrateToSSOInput!) {
      migrateToSSO(input: $input) {
        redirectUri
      }
    }
  `),
}

const query = {
  validToken: graphql(`
    query Token($id: GraphQLString!) {
      token(id: $id) {
        type
        success
        isE2EE
        keys {
          publicKey
          privateKey
          salt
          nonce
          privateKeyRecovery
          privateKeyRecoveryNonce
          systemRecoveryKey
          version
          twoFactorAuthentication
        }
      }

      systemInfo {
        id
        publicKey
      }
    }
  `),
}

const Password = () => {
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [password1, setPassword1] = useState<string | null>(null)
  const [password2, setPassword2] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<boolean | null>(null)
  const [passwordsError, setPasswordsError] = useState<boolean | null>(null)
  const [recoveryKey, setRecoveryKey] = useState<string | null>(null)
  const [recoveryKeyError, setRecoveryKeyError] = useState(false)
  const [recoveryModalVisible, setRecoveryModalVisible] = useState(false)
  const [code2FA, setCode2FA] = useState<string | null>(null)
  const [code2FAError, setCode2FAError] = useState(false)
  const { discoverByToken } = useRegion()
  const { trackMemberCreated } = useAnalytics()
  const { privacyLink, termsLink } = useConfigForProject()

  const { formatMessage } = useIntl()
  const { id: token } = useParams<'id'>()
  const handleError = useCryptoErrorHandler()
  const modal = useModal()

  const [editPassword] = useMutation(mutation.editPassword, {
    onError: error => {
      console.error(error)

      // Handle invalid 2FA code in error under input
      if (error.message === 'Not valid 2FA code') {
        setCode2FAError(true)
      } else {
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      }
    },
  })

  const [migrateToSSO] = useMutation(mutation.migrateToSSO, {
    onError: error => {
      console.error(error)

      // Handle invalid 2FA code in error under input
      if (error.message === 'Not valid 2FA code') {
        setCode2FAError(true)
      } else {
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      }
    },
  })

  const [fetchTokenInfo, { data }] = useLazyQuery(query.validToken, {
    variables: { id: token ?? '' },
    onError: (error: ApolloError) => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: ({ token }) => {
      const tokenType = token?.type
      if (
        token?.success &&
        // we allow only these token types
        (tokenType === TokenType.CreatePassword ||
          tokenType === TokenType.ForgottenPassword ||
          tokenType === TokenType.MigrateFromSSO ||
          tokenType === TokenType.MigrateToSSO)
      ) {
        return
      }

      modal.error({
        title: formatMessage(messages.tokenErrorTitle),
        content: formatMessage(messages.tokenErrorDescription),
        onOk: () => window.location.replace('/'),
        centered: true,
      })
    },
  })

  const tokenPrivateKey = data?.token?.keys?.privateKey ?? ''
  const tokenType = data?.token?.type ?? TokenType.CreatePassword
  const tokenVersion = data?.token?.keys?.version ?? getCurrentEncryptionVersion()
  const systemRecoveryKey = data?.token?.keys?.systemRecoveryKey ?? undefined
  const systemPublicKey = data?.systemInfo?.publicKey ?? ''
  const isE2EE = data?.token?.isE2EE ?? false
  const requireRecoveryKeyField =
    tokenType === TokenType.ForgottenPassword &&
    tokenVersion === getCurrentEncryptionVersion() &&
    (isE2EE || !systemRecoveryKey)

  // Should we require 2FA code in other case as well?
  const require2FAKey = data?.token?.keys?.twoFactorAuthentication

  // biome-ignore lint/correctness/useExhaustiveDependencies(discoverByToken):
  // biome-ignore lint/correctness/useExhaustiveDependencies(fetchTokenInfo):
  // biome-ignore lint/correctness/useExhaustiveDependencies(token):
  useEffect(() => {
    const fetchToken = async () => {
      await discoverByToken(token ?? '')
      await fetchTokenInfo()
    }
    fetchToken()
  }, [])

  const processFormSubmit = async () => {
    if (
      tokenType === TokenType.VerifyVictimEmail ||
      tokenType === TokenType.VerifyTrialEmail ||
      tokenType === TokenType.VerifyPurchaseEmail ||
      !token
    ) {
      return
    }

    const payload = await processCredentialsChange({
      token: tokenType,
      password: password2?.trim() ?? '',
      publicKey: data?.token?.keys?.publicKey ?? '',
      privateKey: tokenPrivateKey,
      name,
      isE2EE,
      systemPublicKey,
      nonce: data?.token?.keys?.nonce ?? '',
      salt: data?.token?.keys?.salt ?? '',
      recoveryKey: systemRecoveryKey ?? recoveryKey ?? undefined,
      privateKeyRecovery: data?.token?.keys?.privateKeyRecovery ?? '',
      privateKeyRecoveryNonce: data?.token?.keys?.privateKeyRecoveryNonce ?? '',
      version: tokenVersion,
      savePersonalKeys,
    })

    if (payload.isErr()) {
      setLoading(false)

      if (requireRecoveryKeyField) {
        setRecoveryKeyError(true)
      } else {
        handleError(payload.error.message)
      }

      return
    }

    const [mutationPayload, recoveryKeyPlain] = payload.value

    if (mutationPayload.tokenType === TokenType.MigrateToSSO) {
      const { tokenType, ...input } = mutationPayload
      const response = await migrateToSSO({
        variables: { input: { ...input, token } },
      })

      if (!response) {
        setLoading(false)
        return
      }

      if (response?.data?.migrateToSSO?.redirectUri) {
        window.location.replace(response.data.migrateToSSO.redirectUri)
        return
      }
    } else {
      const { tokenType, ...input } = mutationPayload
      const response = await editPassword({
        variables: { input: { ...input, token, code: code2FA ?? '' } },
      })

      if (!response || response.errors) {
        setLoading(false)
        return
      }

      Auth.setJwt({ jwt: response.data?.userTokenPassword?.token ?? '' })

      if (tokenType === TokenType.CreatePassword) {
        trackMemberCreated()
      }
    }

    if (isE2EE && recoveryKeyPlain && systemRecoveryKey) {
      setRecoveryKey(recoveryKeyPlain)
      setRecoveryModalVisible(true)
    } else {
      window.location.replace('/')
    }
  }

  return (
    <PageTemplateUnlogged
      publicHeaderProps={{ showLinkTo: 'none' }}
      contentUnderCard={<Recovery recoveryKey={recoveryKey ?? ''} visible={recoveryModalVisible} />}
    >
      <Title>
        {tokenType === TokenType.CreatePassword ? (
          <FormattedMessage {...messages.createPasswordTitle} />
        ) : tokenType === TokenType.ForgottenPassword ? (
          <FormattedMessage {...messages.forgottenPasswordTitle} />
        ) : tokenType === TokenType.MigrateToSSO ? (
          <FormattedMessage {...messages.migrateToSsoTitle} />
        ) : (
          <FormattedMessage {...messages.migrateFromSsoTitle} />
        )}
      </Title>

      <Form
        layout='vertical'
        style={{ width: '100%' }}
        noValidate
        onFinish={async () => {
          setPassword1((password1 || '').trim())
          setPassword2((password2 || '').trim())

          if (tokenType === TokenType.CreatePassword && (!name || !USER_NAME_REGEX.test(name))) {
            setNameError(true)
            return
          }

          if (requireRecoveryKeyField && !recoveryKey?.trim()) {
            setRecoveryKeyError(true)
            return
          }

          if (require2FAKey && !code2FA?.trim()) {
            setCode2FAError(true)
            return
          }

          if (tokenType !== TokenType.MigrateFromSSO && tokenType !== TokenType.MigrateToSSO) {
            if (!password1 || !isPasswordValid(password1 ?? '')) {
              setPasswordError(true)
              return
            }

            if (password1 !== password2) {
              setPasswordsError(true)
              return
            }
          }

          if (!loading) {
            setLoading(true)
            await processFormSubmit()
          }
        }}
      >
        {tokenType === TokenType.CreatePassword && (
          <Form.Item
            label={<FormattedMessage {...messages.nameLabel} />}
            {...(nameError && {
              validateStatus: 'error',
              help: <FormattedMessage {...sharedMessages.invalidNameError} />,
            })}
          >
            <Input
              autoComplete='name'
              size='large'
              value={name}
              onChange={e => {
                setName(e.target.value)
                setNameError(false)
              }}
              data-test='create-password-name-input'
            />
          </Form.Item>
        )}
        {tokenType !== TokenType.MigrateToSSO && (
          <Form.Item
            label={<FormattedMessage {...sharedMessages.passwordLabel} />}
            {...(passwordError && {
              validateStatus: 'error',
              help: (
                <FormattedMessage
                  {...sharedMessages.invalidPasswordError}
                  values={{ minLength: USER_PASSWORD_MIN_LENGTH }}
                />
              ),
            })}
          >
            <PasswordInputWithPopover
              size='large'
              type='password'
              autoComplete='new-password'
              value={password1 ?? undefined}
              onChange={e => {
                setPassword1(e.target.value)
                setPasswordError(false)
                setPasswordsError(false)
              }}
              data-test='create-password-password-input'
            />
          </Form.Item>
        )}
        <Form.Item
          label={<FormattedMessage {...messages.password2Label} />}
          {...(passwordsError && {
            validateStatus: 'error',
            help: <FormattedMessage {...sharedMessages.passwordsDontMatchError} />,
          })}
        >
          <PasswordInputWithIcon
            size='large'
            autoComplete='new-password'
            onChange={e => {
              setPassword2(e.target.value)
              setPasswordsError(false)
            }}
            data-test='create-password-repeat-password-input'
          />
        </Form.Item>
        {require2FAKey && (
          <Form.Item
            label={<FormattedMessage {...messages.codeLabel} />}
            {...(code2FAError && {
              validateStatus: 'error',
              help: <FormattedMessage {...messages.codeError} />,
            })}
          >
            <Input
              size='large'
              type='text'
              inputMode='numeric'
              autoComplete='off'
              onChange={e => {
                setCode2FA(e.target.value)
                setCode2FAError(false)
              }}
            />
          </Form.Item>
        )}
        {requireRecoveryKeyField && (
          <Form.Item
            label={<FormattedMessage {...messages.recoveryLabel} />}
            {...(recoveryKeyError && {
              validateStatus: 'error',
              help: <FormattedMessage {...messages.recoveryError} />,
            })}
          >
            <Input
              size='large'
              autoComplete='off'
              onChange={e => {
                setRecoveryKey(e.target.value)
                setRecoveryKeyError(false)
              }}
            />
          </Form.Item>
        )}
        <Button
          htmlType='submit'
          type='primary'
          block
          loading={loading}
          data-test='create-password-submit-button'
        >
          <FormattedMessage
            {...(tokenType === TokenType.ForgottenPassword
              ? messages.login
              : tokenType === TokenType.MigrateToSSO
                ? sharedMessages.enable
                : tokenType === TokenType.MigrateFromSSO
                  ? sharedMessages.disable
                  : messages.createAccountSubmit)}
          />
        </Button>
      </Form>
      {tokenType === TokenType.CreatePassword && (
        <ConditionsDisclaimer>
          <FormattedMessage
            {...messages.description}
            values={{
              terms: terms => (
                <a target='_blank' rel='noopener noreferrer' href={termsLink}>
                  {terms}
                </a>
              ),
              privacy: privacy => (
                <a target='_blank' rel='noopener noreferrer' href={privacyLink}>
                  {privacy}
                </a>
              ),
            }}
          />
        </ConditionsDisclaimer>
      )}
    </PageTemplateUnlogged>
  )
}

const Title = styled(AntTitle)`
  padding-bottom: 2.5rem;
`

const ConditionsDisclaimer = styled(Typography.Text)`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-top: 2.5rem;
`

export default Password
