import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { ulTranslate02 } from '@faceup/icons/ulTranslate02'
import { ulX } from '@faceup/icons/ulX'
import { useLanguageName } from '@faceup/localization'
import { ModalConfirm, useLocalStorage } from '@faceup/ui'
import { Button, Select, Space, Tooltip, useMessage } from '@faceup/ui-base'
import { DEFAULT_LANGUAGE, type Language } from '@faceup/utils'
import { ActionIcon, Box, Checkbox } from '@mantine/core'
import { useEffect, useState } from 'react'

type Props = {
  defaultLanguages: [source: Language | 'auto', target: Language | null]
  onTranslate: (source: Language | 'auto', target: Language) => void
  onShowOriginal: () => void
  loading?: boolean
  disabledReason?: 'e2ee'
  translations: {
    detectLanguage: string
    translate: string
    showOriginal: string
    to: string
    sameLanguageError: string
    disabledReasonE2EE: string
    onUseWarningTitle: string
    onUseWarningContent: string
    onUseWarningDoNotShowAgain: string
  }
  onClose?: () => void
}

const TRANSLATION_WARNING_DISABLED = 'translationWarningModalDisable'

const TranslateMenu = ({
  defaultLanguages: [defaultSource, defaultTarget],
  onTranslate,
  onShowOriginal,
  translations,
  loading = false,
  disabledReason,
  onClose,
}: Props) => {
  const [source, setSource] = useState<Language | 'auto'>('auto')
  const [target, setTarget] = useState<Language>(DEFAULT_LANGUAGE)
  const [isConfirmModalDisplayed, setIsConfirmModalDisplayed] = useState(false)
  const [isWarningModalDisabled, setIsWarningModalDisabled] = useLocalStorage(
    TRANSLATION_WARNING_DISABLED,
    false
  )
  const message = useMessage()
  const { getLanguageList } = useLanguageName()
  useEffect(() => {
    setSource(defaultSource ?? 'auto')
    setTarget(defaultTarget ?? DEFAULT_LANGUAGE)
  }, [defaultSource, defaultTarget])

  const triggerOnShowOriginal = onShowOriginal
  const triggerOnTranslate = () => {
    if (source === target) {
      message.error(translations.sameLanguageError)
      setIsConfirmModalDisplayed(false)
      return
    }

    onTranslate(source, target)
    setIsConfirmModalDisplayed(false)
  }

  const isTranslated = defaultSource === source && defaultTarget === target
  const translateButton = (
    <Button
      type='link'
      size='small'
      loading={loading}
      disabled={Boolean(disabledReason)}
      onClick={() => {
        if (isTranslated) {
          triggerOnShowOriginal()
        } else if (isWarningModalDisabled) {
          triggerOnTranslate()
        } else {
          setIsConfirmModalDisplayed(true)
        }
      }}
      data-test='translate'
    >
      {isTranslated ? translations.showOriginal : translations.translate}
    </Button>
  )

  return (
    <Box
      sx={{
        padding: `3px ${onClose === undefined ? '12px' : '4px'} 3px 9px`,
        border: '1px solid #e1e5e8',
        borderRadius: 16,
      }}
    >
      <Space wrap style={{ justifyContent: 'center' }}>
        <UntitledIcon icon={ulTranslate02} />
        <LanguageSelect
          size='small'
          variant='borderless'
          value={source}
          isActive={!isTranslated}
          disabled={Boolean(disabledReason)}
          onChange={language => setSource(language as Language | 'auto')}
          data-test='source-language-select'
          options={[
            { label: translations.detectLanguage, value: 'auto' },
            ...getLanguageList().map(({ language, name }) => ({ label: name, value: language })),
          ]}
        />
        <span style={{ color: '#688699' }}>{translations.to}</span>
        <LanguageSelect
          size='small'
          variant='borderless'
          isActive={isTranslated}
          disabled={Boolean(disabledReason)}
          value={target ?? DEFAULT_LANGUAGE}
          onChange={language => setTarget(language as Language)}
          data-test='target-language-select'
          options={getLanguageList().map(({ language, name }) => ({
            label: name,
            value: language,
          }))}
        />
        {disabledReason ? (
          <Tooltip title={disabledReason === 'e2ee' ? translations.disabledReasonE2EE : ''}>
            {translateButton}
          </Tooltip>
        ) : (
          translateButton
        )}
        {onClose !== undefined && (
          <ActionIcon
            radius='lg'
            variant='subtle'
            size='sm'
            onClick={() => {
              triggerOnShowOriginal()
              onClose()
            }}
            data-test='close-translation'
          >
            <UntitledIcon icon={ulX} />
          </ActionIcon>
        )}
      </Space>
      <ModalConfirm
        opened={isConfirmModalDisplayed}
        title={translations.onUseWarningTitle}
        onConfirm={triggerOnTranslate}
        onClose={() => {
          setIsConfirmModalDisplayed(false)
          setIsWarningModalDisabled(false)
        }}
        dataTest='translation-warning-modal'
      >
        <Space direction='vertical'>
          <p>{translations.onUseWarningContent}</p>
          <Checkbox
            checked={isWarningModalDisabled}
            label={translations.onUseWarningDoNotShowAgain}
            onChange={() => setIsWarningModalDisabled(prev => !prev)}
          />
        </Space>
      </ModalConfirm>
    </Box>
  )
}

const LanguageSelect = styled(Select, { shouldForwardProp: prop => prop !== 'isActive' })<{
  isActive?: boolean
}>`
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#062d46' : '#688699')};

  .ant-select-selection-item {
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  }
`

export default TranslateMenu
