import { useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { ulSearchSm } from '@faceup/icons/ulSearchSm'
import { Form } from '@faceup/ui'
import { Empty, Input, Select, Spin, notification, useMessage } from '@faceup/ui-base'
import { type Country, type Language, RegistrationReason } from '@faceup/utils'
import { useEffect, useState } from 'react'
import CountrySelect from '../../../../Components/CountrySelect'
import { LanguageSelect } from '../../../../Components/LanguageSelect'
import { registrationReasonMessages, sharedMessages } from '../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../TypedIntl'
import { graphql } from '../../../../__generated__'
import type { FormValue } from '../../../../types'
import { StepHeading } from './StepHeading'

const messages = defineMessages({
  title: 'Administration.registration.fillInfo.step3.title',
  description: 'Administration.registration.fillInfo.step3.description',
  institutionName: 'Administration.registration.fillInfo.institutionName',
  selectedRegisteredSchoolError:
    'Administration.registration.fillInfo.selectedRegisteredSchoolError',
  schoolNotFound: 'Shared.global.schoolNotFound',
  address: 'Administration.label.address',
  registrationReason: 'Administration.registration.reason',
  country: 'Administration.services.country',
  countryHint: 'Administration.registration.fillInfo.country.hint',
  language: 'Administration.registration.fillInfo.language',
})

const query = {
  SearchPublicSchools: graphql(`
    query SearchPublicSchoolsQuery($name: GraphQLString) {
      searchPublicSchools(name: $name, showTestingSchool: false) {
        country
        regionId
        institutionId
        address
        institutionName
        isRegistered
        isTesting
        hasActivePassword
        defaultFormId
      }
    }
  `),
}

type Step3Props = {
  isNntbSchool: boolean
  institutionName: FormValue
  setInstitutionName: (value: FormValue) => void
  institutionId: string
  setInstitutionId: (value: string) => void
  address: FormValue
  setAddress: (value: FormValue) => void
  country: FormValue<Country | null>
  setCountry: (value: FormValue<Country | null>) => void
  language: FormValue<Language>
  setLanguage: (value: FormValue<Language>) => void
  registrationReason: FormValue<RegistrationReason | null>
  setRegistrationReason: (value: FormValue<RegistrationReason | null>) => void
}

export const Step3 = (props: Step3Props) => {
  const {
    isNntbSchool,
    institutionName,
    setInstitutionName,
    setInstitutionId,
    address,
    setAddress,
    country,
    setCountry,
    language,
    setLanguage,
    registrationReason,
    setRegistrationReason,
  } = props
  const message = useMessage()
  const { formatMessage } = useIntl()
  const [nameInput, setNameInput] = useState({ value: '', error: false })

  const [searchSchools, { loading: schoolsLoading, data, refetch, called }] = useLazyQuery(
    query.SearchPublicSchools,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      variables: {
        name: nameInput.value,
      },
    }
  )

  useEffect(() => {
    if (called) {
      refetch()
    } else if (nameInput.value.trim() !== '') {
      searchSchools()
    }
  }, [nameInput.value, called, refetch, searchSchools])

  const filteredSchools = data?.searchPublicSchools ?? []

  return (
    <>
      <StepHeading
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
      />
      <Form.Item
        label={<FormattedMessage {...messages.institutionName} />}
        withAsterisk
        errorMessage={
          institutionName.error && <FormattedMessage {...sharedMessages.invalidInputError} />
        }
      >
        {isNntbSchool ? (
          <Select<string>
            value={institutionName.value}
            showSearch
            style={{ display: 'flex' }}
            filterOption={false}
            suffixIcon={null}
            allowClear
            showAction={['focus']}
            onSearch={value => {
              setInstitutionName({ value, error: false })
              setNameInput({ value, error: false })
            }}
            onClear={() => {
              setInstitutionName({ value: '', error: false })
              setNameInput({ value: '', error: false })
              setAddress({ value: '', error: false })
              setCountry({ value: null, error: false })
            }}
            onSelect={(id: string) => {
              const school = filteredSchools.find(school => school?.institutionId === id)

              if (!school) {
                return
              }

              if (school.isRegistered) {
                setInstitutionName({ value: '', error: false })
                setNameInput({ value: '', error: false })
                setAddress({ value: '', error: false })
                setCountry({ value: null, error: false })
                message.error(formatMessage(messages.selectedRegisteredSchoolError))

                return
              }

              setInstitutionId(school.institutionId ?? '')
              setInstitutionName({ value: school.institutionName ?? '', error: false })
              setAddress({ value: school.address ?? '', error: false })
              setCountry({ value: school.country ?? null, error: false })
            }}
            placeholder={<UntitledIcon icon={ulSearchSm} />}
            notFoundContent={
              schoolsLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spin size='small' />
                </div>
              ) : institutionName.value === '' ? null : (
                <Empty
                  styles={{ image: { height: 60 } }}
                  description={<FormattedMessage {...messages.schoolNotFound} />}
                />
              )
            }
            options={filteredSchools.map(school => ({
              label: (
                <>
                  {school?.institutionName}
                  <AddressText>{school?.address ?? ''}</AddressText>
                </>
              ),
              value: school?.institutionId ?? '',
            }))}
          />
        ) : (
          <Input
            autoComplete='organization'
            value={institutionName.value}
            onChange={({ target: { value } }) => setInstitutionName({ value, error: false })}
            data-test='FillInfoContent-institutionName'
          />
        )}
      </Form.Item>

      {isNntbSchool ? (
        <Form.Item label={<FormattedMessage {...messages.address} />}>
          <Input disabled value={address.value} />
        </Form.Item>
      ) : (
        <Form.Item
          label={<FormattedMessage {...messages.country} />}
          hint={<FormattedMessage {...messages.countryHint} />}
          withAsterisk
          errorMessage={
            country.error && <FormattedMessage {...sharedMessages.invalidAddressError} />
          }
        >
          <CountrySelect
            value={country.value}
            onChange={(value: Country) => setCountry({ value, error: false })}
            data-test='FillInfoContent-country'
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
      <Form.Item label={<FormattedMessage {...messages.language} />} withAsterisk>
        <LanguageSelect
          value={language.value}
          onChange={(value: Language) => setLanguage({ value, error: false })}
          data-test='FillInfoContent-language'
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item label={<FormattedMessage {...messages.registrationReason} />}>
        <Select<RegistrationReason>
          data-test='FillInfoContent-registrationReason'
          value={registrationReason.value}
          allowClear
          onChange={value => setRegistrationReason({ value, error: false })}
          options={Object.values(RegistrationReason).map(reason => ({
            value: reason,
            label: <FormattedMessage {...registrationReasonMessages[reason]} />,
          }))}
        />
      </Form.Item>
    </>
  )
}

const AddressText = styled.div`
  font-size: 12px;
  color: rgba(80, 80, 80, 0.8);
`
