import { useMutation } from '@apollo/client'
import { UserContext } from '@faceup/member'
import { InputPhone, Modal, type ModalProps } from '@faceup/ui'
import { Button, Form, notification, useMessage } from '@faceup/ui-base'
import type { ContactIssue } from '@faceup/utils'
import { Stack, Title } from '@mantine/core'
import { useContext, useState } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { useMotherId } from '../../hooks/useMotherId'

const messages = defineMessages({
  title: 'Administration.contactSalesModal.byPhone.title',
  heading: 'Administration.contactSalesModal.byPhone.heading',
  contactedSalesMessage: 'Administration.contactedSales.heading',
})

const mutations = {
  ContactSalesModalByPhoneMutation: graphql(`
    mutation ContactSalesModalByPhoneMutation($input: ContactSalesByPhoneInput!) {
      contactSalesByPhone(input: $input) {
        success
      }
    }
  `),
}

type ContactSalesModalByPhoneProps = { contactIssue: ContactIssue; note?: string } & Required<
  Pick<ModalProps, 'opened' | 'onClose'>
>

const ContactSalesModalByPhone = ({
  contactIssue,
  note,
  ...props
}: ContactSalesModalByPhoneProps) => {
  const { viewer } = useContext(UserContext)
  const [phone, setPhone] = useState<{ value: string; error?: boolean }>({
    value: viewer?.phone ?? '',
  })
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()
  const message = useMessage()

  const [contactByPhone, { loading }] = useMutation(mutations.ContactSalesModalByPhoneMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const submitForm = () => {
    let isEverythingOkay = true
    if (!phone.value || phone.value.trim().length < 6) {
      setPhone(prevState => ({ ...prevState, error: true }))
      isEverythingOkay = false
    }

    if (!isEverythingOkay) {
      return
    }

    void contactByPhone({
      variables: {
        input: {
          phone: phone.value,
          issue: contactIssue,
          note,
          motherId: getMotherId(),
        },
      },
      onCompleted: () => {
        void message.success(formatMessage(messages.contactedSalesMessage))
        props.onClose()
      },
    })
  }

  return (
    <Modal {...props} title={<FormattedMessage {...messages.title} />} width={620}>
      <Stack spacing={32}>
        <Title order={3} align='center'>
          <FormattedMessage {...messages.heading} />
        </Title>
        <Form onFinish={submitForm}>
          <Stack spacing={24} align='center'>
            <Form.Item
              {...(phone.error && {
                validateStatus: 'error',
                help: <FormattedMessage {...sharedMessages.invalidPhoneError} />,
              })}
            >
              <InputPhone
                value={phone.value}
                onChange={value => setPhone({ value })}
                containerStyle={{
                  width: 440,
                }}
              />
            </Form.Item>
            <Button type='primary' htmlType='submit' loading={loading}>
              <FormattedMessage {...sharedMessages.send} />
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Modal>
  )
}

export default ContactSalesModalByPhone
