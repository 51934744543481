import { Flex, Skeleton, Typography } from '@faceup/ui-base'
import { type HTMLAttributes, type ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { HintIcon } from '../AdminLayout'
import styles from './assets/Card.module.css'

export type CardProps = {
  loading?: boolean
} & Omit<HTMLAttributes<HTMLDivElement>, 'title'>

const _Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { children, loading = false, className, ...otherProps } = props

  return (
    <div
      ref={ref}
      {...otherProps}
      // We want to enforce padding when loading
      className={`${styles.cardWrapper} ${twMerge(className, loading ? 'p-6 sm:p-6' : '')}`}
    >
      {loading ? <Skeleton /> : children}
    </div>
  )
})

export type SectionProps = {
  children?: ReactNode
  loading?: boolean
}

export const Section = ({ children, loading = false }: SectionProps) => {
  return (
    <div className={`${styles.segmentWrapper} ${loading ? 'p-6' : ''}`}>
      {loading ? <Skeleton /> : children}
      <hr className={styles.divider} />
    </div>
  )
}

type HeaderProps = {
  children: ReactNode
  extra?: ReactNode
  hint?: ReactNode
}

const Header = ({ children, extra, hint }: HeaderProps) => (
  <div className={styles.titleWrapper}>
    <Typography.Title level={5} className={styles.title}>
      <Flex align='center' gap='small'>
        {children}
        {hint && <HintIcon title={hint} />}
      </Flex>
    </Typography.Title>
    <Flex>{extra}</Flex>
  </div>
)

type ContentProps = { children: ReactNode }

const Content = forwardRef<HTMLDivElement, ContentProps>(({ children }, ref) => (
  <div ref={ref} className={styles.contentWrapper}>
    {children}
  </div>
))

type CardType = typeof _Card & {
  Section: typeof Section
  Header: typeof Header
  Content: typeof Content
}

export const Card: CardType = _Card as CardType

Card.Section = Section
Card.Header = Header
Card.Content = Content
