import { UntitledIcon } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Modal as ANTDModal, Button, Flex, Typography } from '@faceup/ui-base'
import type { JSX, ReactNode } from 'react'
import { Segment, type SegmentProps } from '../CoreComponents/Segment'

const { Title } = Typography

type ModalComponent = (props: ModalProps) => JSX.Element
type ModalSegmentComponent = (props: ModalSegmentProps) => JSX.Element
export type ModalSubcomponents = { Segment: ModalSegmentComponent }
type ComplexComponent = ModalComponent & ModalSubcomponents

type ModalWidth = 520 | 620 | 644 | 800 | 950

export type ModalProps = {
  children: ReactNode
  opened: boolean
  onClose?: () => void
  width?: ModalWidth
  footer?: ReactNode
  'data-test'?: string
} & ModalTitleProps

type ModalTitleProps = {
  title?: ReactNode
  onClickBackArrow?: () => void
}

export const Modal: ComplexComponent = (_props: ModalProps) => {
  const {
    children,
    opened,
    onClose,
    width,
    title,
    footer,
    onClickBackArrow,
    'data-test': dataTest,
    ...props
  } = _props
  return (
    <ANTDModal
      title={<ModalTitle title={title} onClickBackArrow={onClickBackArrow} />}
      open={opened}
      onCancel={onClose ? onClose : () => null}
      footer={footer ?? null}
      centered
      width={width}
      bodyProps={{ 'data-test': dataTest }}
      {...props}
      closeIcon={
        onClose ? (
          <Flex justify='flex-end'>
            <UntitledIcon icon={ulXClose} color='#547599' size={24} />
          </Flex>
        ) : null
      }
    >
      {children}
    </ANTDModal>
  )
}

const ModalTitle = (props: ModalTitleProps) => {
  const { title, onClickBackArrow } = props

  return (
    <Flex align='flex-start'>
      {onClickBackArrow && (
        // @TODO: the padding is not specified in the design - should be done more systematically for clickable icons
        <Button
          type='text'
          onClick={onClickBackArrow}
          style={{
            padding: '0px 10px',
            height: '32px',
            width: '32px',
            position: 'relative',
            top: '-6px',
          }}
        >
          <UntitledIcon icon={ulArrowLeft} size={20} />
        </Button>
      )}
      <Title level={5} color='text'>
        {title}
      </Title>
    </Flex>
  )
}

type ModalSegmentProps = Omit<SegmentProps, 'paddingContent' | 'paddingHeader' | 'marginDivider'>

export const ModalSegment: ModalSegmentComponent = (props: ModalSegmentProps) => (
  <Segment {...props} marginDivider='24px 0' />
)

Modal.Segment = ModalSegment
