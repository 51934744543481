import { Form, MultiSelect, Prompt } from '@faceup/ui'
import { Button, Select } from '@faceup/ui-base'
import { FormItemType, type Language } from '@faceup/utils'
import { Checkbox, Divider, Grid, Stack } from '@mantine/core'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { formItemTypeMessages, sharedMessages } from '../../../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../../__generated__'
import { ReportSourceHintFormItem, ReportSourceNameFormItem } from '../../../../components'
import { OptionsList } from './OptionsList'
import type { SelectOption } from './hooks/useFormItemForm'

const messages = defineMessages({
  allCategories: 'Administration.customization.formItems.allCategories',
  labelCategories: 'Administration.customization.formItems.label.categories',
  labelType: 'Administration.customization.formItems.label.type',
  labelIsRequired: 'Shared.global.invalidInput',
  leaveQuestion: 'Administration.customization.formItems.unsavedChangesMessage',
})

const messagesButton = defineMessages<ButtonVariant>({
  create: 'Administration.customization.formItems.form.button.create',
  update: 'Administration.customization.formItems.form.button.update',
})

const fragments = {
  FormItemForm_reportSource: graphql(`
    fragment FormItemForm_reportSource on ReportSource {
      id
      categories {
        id
        nameTranslations {
          translation
          language
        }
      }
    }
  `),
}

export const notMovableSpecialFormItemsDef: FormItemType[] = [
  FormItemType.Category,
  FormItemType.OrganizationalUnit,
  FormItemType.Classroom,
]

export const specialFormItemsDef: FormItemType[] = [
  ...notMovableSpecialFormItemsDef,
  FormItemType.MoreInformation,
  FormItemType.SenderName,
]

type ButtonVariant = 'create' | 'update'

const allValue = 'all'

export type Value<T> = { value: T; error?: boolean }

export type FormItemFormValues = {
  label: Value<string>
  hint: Value<string>
  isRequired: Value<boolean>
  categories: Value<string[]>
  type: Value<FormItemType | null>
  options: Value<SelectOption[]>
}

export type FormItemFormSetters = {
  setLabel: Dispatch<SetStateAction<Value<string>>>
  setCategories: Dispatch<SetStateAction<Value<string[]>>>
  setHint: Dispatch<SetStateAction<Value<string>>>
  setIsRequired: Dispatch<SetStateAction<Value<boolean>>>
  setType: Dispatch<SetStateAction<Value<FormItemType | null>>>
  setOptions: Dispatch<SetStateAction<Value<SelectOption[]>>>
}

type FormItemFormProps = {
  reportSource: FragmentType<typeof fragments.FormItemForm_reportSource>
  onClose: () => void
  buttonVariant: ButtonVariant
  onSubmit: () => void
  values: FormItemFormValues
  setters: FormItemFormSetters
  isSubmitting: boolean
  hasUnsavedChanges: boolean
  isSubmitButtonDisabled: boolean
  language: Language
  onOptionsReorder: (fromIndex: number, toIndex: number) => void
  isTypeDisabled: boolean
  onDeleteOption: (optionId: string) => void
}

const FormItemForm = ({
  reportSource: _reportSource,
  onClose,
  onSubmit,
  values: { label, categories, hint, isRequired, type, options },
  setters: { setLabel, setCategories, setHint, setIsRequired, setType, setOptions },
  buttonVariant,
  isSubmitting,
  isSubmitButtonDisabled,
  hasUnsavedChanges,
  language,
  onOptionsReorder,
  isTypeDisabled,
  onDeleteOption,
}: FormItemFormProps) => {
  const reportSource = getFragmentData(fragments.FormItemForm_reportSource, _reportSource)
  const [wasCategoryChanged, setWasCategoryChanged] = useState(false)
  const { formatMessage } = useIntl()

  const availableOptionsWithoutAllOption = reportSource.categories.map(category => ({
    label:
      category.nameTranslations?.find(translation => translation.language === language)
        ?.translation ?? '',
    value: category.id,
  }))
  const availableOptionsWithAllOption = [
    { value: allValue, label: formatMessage(messages.allCategories) },
    ...availableOptionsWithoutAllOption,
  ]

  useEffect(() => {
    if (categories.value.length === 0 && !wasCategoryChanged && buttonVariant === 'create') {
      // I feel this shouldn't be here: the intention is to use all available categories as
      // default value when creating new form item
      setCategories({ value: reportSource.categories.map(({ id }) => id) })
    }
  }, [categories, reportSource.categories, setCategories, wasCategoryChanged, buttonVariant])

  const availableCategories = reportSource.categories

  return (
    <Form onSubmit={onSubmit}>
      <Prompt when={hasUnsavedChanges} message={formatMessage(messages.leaveQuestion)} />
      <Grid>
        <Grid.Col span={12}>
          <Stack>
            <ReportSourceNameFormItem
              value={label.value}
              onChange={value => setLabel({ value })}
              errorMessage={
                label.error ? <FormattedMessage {...sharedMessages.invalidInputError} /> : null
              }
            />
            <Form.Item
              label={<FormattedMessage {...messages.labelType} />}
              withAsterisk
              {...(type?.error && {
                validateStatus: 'error',
                help: <FormattedMessage {...sharedMessages.invalidInputError} />,
              })}
            >
              <Select<FormItemType>
                allowClear
                disabled={isTypeDisabled}
                value={type.value}
                style={{ width: '100%' }}
                onChange={values => {
                  setType({
                    value: values,
                  })
                }}
                options={Object.values(FormItemType)
                  .filter(formItemType => !specialFormItemsDef.includes(formItemType))
                  .map(formItemType => ({
                    value: formItemType,
                    label: formatMessage(formItemTypeMessages[formItemType]),
                  }))}
              />
            </Form.Item>
            {(type.value === FormItemType.Select || type.value === FormItemType.MultiSelect) && (
              <OptionsList
                options={options.value}
                setOptions={setOptions}
                onReorder={onOptionsReorder}
                onDeleteOption={onDeleteOption}
                shouldDisplayDeleteButton={
                  type.value === FormItemType.MultiSelect
                    ? options.value.length > 2
                    : options.value.length > 1
                }
              />
            )}
            <Form.Item
              label={<FormattedMessage {...messages.labelCategories} />}
              withAsterisk
              {...(categories?.error && {
                validateStatus: 'error',
                help: <FormattedMessage {...sharedMessages.invalidInputError} />,
              })}
            >
              <MultiSelect
                value={availableCategories
                  .filter(
                    availableCategory => categories.value.includes(availableCategory.id) ?? false
                  )
                  .map(category => category.id)}
                onChange={values => {
                  setWasCategoryChanged(true)
                  if (values.includes(allValue)) {
                    setCategories(prev => ({
                      ...prev,
                      value: availableCategories.map(({ id }) => id),
                    }))
                  } else {
                    setCategories(prev => ({
                      ...prev,
                      value: values,
                    }))
                  }
                }}
                options={availableOptionsWithAllOption}
              />
            </Form.Item>
            <ReportSourceHintFormItem
              value={hint.value}
              onChange={value => setHint({ value })}
              errorMessage={
                hint.error && <FormattedMessage {...sharedMessages.invalidInputError} />
              }
            />
            <Divider />
            <Form.Item>
              <Checkbox
                label={<FormattedMessage {...messages.labelIsRequired} />}
                onChange={_event => {
                  setIsRequired(prev => ({ ...prev, value: !prev.value }))
                }}
                checked={isRequired.value}
              />
            </Form.Item>
            <Divider />
          </Stack>
        </Grid.Col>

        <Grid.Col span={12}>
          <Form.ButtonGroup>
            <Button onClick={onClose}>
              <FormattedMessage {...sharedMessages.cancel} />
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={isSubmitting}
              disabled={isSubmitButtonDisabled}
            >
              <FormattedMessage {...messagesButton[buttonVariant]} />
            </Button>
          </Form.ButtonGroup>
        </Grid.Col>
      </Grid>
    </Form>
  )
}

export default FormItemForm
