import { Paper } from '@faceup/ui'
import { Col, Row, Typography } from '@faceup/ui-base'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { AnswersChart } from './AnswersChart'

const messages = defineMessages({
  totalAnswers: 'Administration.surveys.submissions.statistics.totalAnswers',
})

type SubmissionStatistics_reportSource = ResultOf<
  typeof fragments.SubmissionStatistics_reportSource
>
type SubmissionSummaryConfig = SubmissionStatistics_reportSource['config']
type SubmissionSummaryFormItems = Extract<
  SubmissionSummaryConfig,
  { __typename: 'SurveyChannelConfiguration' }
>['formItems']

const fragments = {
  SubmissionStatistics_reportSource: graphql(`
    fragment SubmissionStatistics_reportSource on ReportSource {
      id
      name
      config {
        ... on SurveyChannelConfiguration {
          submissionsCount
          formItems {
            id
            order
            type
            isRequired
            labelTranslations {
              translation
            }
            answersStatistics {
              ...AnswersTable_submissionStatistics
              ...AnswersChart_submissionStatistics
              answersCount
              statistics {
                label
                count
                relativeFrequency
              }
            }
          }
        }
      }
    }
  `),
}

export const SubmissionsStatistics = ({
  data: _data,
}: { data: FragmentType<typeof fragments.SubmissionStatistics_reportSource> }) => {
  const data = getFragmentData(fragments.SubmissionStatistics_reportSource, _data)
  const config =
    data?.config?.__typename === 'SurveyChannelConfiguration' ? data?.config : undefined
  const questions = config?.formItems ?? []
  const submissionsCount = config?.submissionsCount ?? 0

  return (
    <Paper elevation={5} outline='unset'>
      <Typography.Title level={5} className='pt-[32px] ps-[32px]'>
        <FormattedMessage {...messages.totalAnswers} values={{ count: submissionsCount }} />
      </Typography.Title>
      <Row gutter={[24, 24]} style={{ padding: '24px 32px' }}>
        {questions.map(question => (
          <Col span={12} key={question.id}>
            <Question formItem={question} />
          </Col>
        ))}
      </Row>
    </Paper>
  )
}

const Question = ({ formItem }: { formItem: SubmissionSummaryFormItems[number] }) => {
  const label = (
    <>
      {formItem.labelTranslations[0]?.translation}
      {formItem.isRequired && <Typography.Text type='danger'>*</Typography.Text>}
    </>
  )
  const answersStatistics = formItem.answersStatistics

  return <AnswersChart label={label} answersStatistics={answersStatistics} />
}
