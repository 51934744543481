import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { Upsell, type UpsellData, UpsellIcon, upsellDisabled } from '@faceup/institution'
import { type RouteCallback, useNavigate } from '@faceup/router'
import { Badge, Box, type BoxProps, Group, Paper, Stack, Text, UnstyledButton } from '@mantine/core'
import type { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import PermissionTooltipWrapper from '../../../Components/PermisionTooltipWrapper'
import { defineMessages } from '../../../TypedIntl'

const messages = defineMessages({
  comingSoon: 'Administration.connectReportSource.comingSoon',
})

export type Source = {
  key: string
  heading: ReactNode
  description: ReactNode
  icon: UntitledIconData
  isComingSoon?: boolean
  isInaccessible?: boolean
  isDisabled?: boolean
  route: RouteCallback
  upsell?: UpsellData
}

export type ConnectSourceCardProps = {
  source: Source
} & BoxProps

export const ConnectSourceCard = (props: ConnectSourceCardProps) => {
  const {
    key,
    heading,
    description,
    icon,
    route,
    upsell: _upsell,
    isComingSoon,
    isInaccessible,
    isDisabled: _isDisabled,
  } = props.source
  const navigate = useNavigate()

  const isDisabled = isComingSoon || isInaccessible || _isDisabled

  // If disabled we don't want to show the upsell
  const upsell = _isDisabled ? undefined : _upsell

  return (
    <Upsell
      upsell={upsell ?? upsellDisabled}
      sx={{
        height: '100%',
      }}
    >
      <PermissionTooltipWrapper areTooltipsDisabled={!isInaccessible}>
        <UnstyledButton
          sx={{
            cursor: isDisabled ? 'not-allowed' : undefined,
            height: '100%',
            width: '100%',
          }}
          onClick={() => {
            if (!isDisabled) {
              navigate(route ?? '')
            }
          }}
          data-test={`connect-source-card-${key}`}
        >
          <Paper
            sx={{
              padding: '24px 16px',
              opacity: isDisabled ? 0.5 : 1,
              height: '100%',
              position: 'relative',
            }}
            shadow='sm'
            radius={6}
          >
            {isComingSoon && (
              <Badge
                sx={{
                  position: 'absolute',
                  zIndex: 10,
                  top: -10, // height of badge
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textTransform: 'none',
                }}
              >
                <FormattedMessage {...messages.comingSoon} />
              </Badge>
            )}

            <Stack align='center' spacing={0}>
              <Group
                sx={({ colors }) => ({
                  background: isDisabled ? '#F5F7F8' : colors.primary[0],
                  color: isDisabled ? colors.prussianBlue[6] : colors.primary[7],
                  width: 64,
                  height: 64,
                  borderRadius: '50%',
                  fontSize: 26,
                })}
                mb={24}
                align='center'
                position='center'
              >
                <Box icon={icon} component={UntitledIcon} />
              </Group>
              <Text inline mb={8} align='center' size={16} c='prussianBlue.9' weight={700}>
                {heading} <UpsellIcon />
              </Text>
              <Text size={14} c='prussianBlue.6' align='center'>
                {description}
              </Text>
            </Stack>
          </Paper>
        </UnstyledButton>
      </PermissionTooltipWrapper>
    </Upsell>
  )
}
