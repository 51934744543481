import { AnswerCard } from '@faceup/ui'
import { Table, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'

const messages = defineMessages({
  answerColumnLabel: 'Administration.surveys.submissions.statistics.answers',
  answerCountLabel: 'Administration.surveys.submissions.statistics.answersCount',
  answerShareLabel: 'Administration.surveys.submissions.statistics.answersShare',
  xAnswers: 'Administration.surveys.submissions.statistics.xAnswers',
  emptyLabel: 'Administration.surveys.submission.statistics.table.emptyLabel',
  emptyDescription: 'Administration.surveys.submission.statistics.table.emptyDescription',
})

const fragments = {
  AnswersTable_submissionStatistics: graphql(`
    fragment AnswersTable_submissionStatistics on AnswersStatistics {
      answersCount
      statistics {
        label
        count
        relativeFrequency
      }
    }
  `),
}

type SurveyTableProps = {
  label: ReactNode
  answersStatistics: FragmentType<typeof fragments.AnswersTable_submissionStatistics>
}

export const AnswersTable = ({
  label,
  answersStatistics: _answersStatistics,
}: SurveyTableProps) => {
  const answersStatistics = getFragmentData(
    fragments.AnswersTable_submissionStatistics,
    _answersStatistics
  )
  const { answersCount, statistics } = answersStatistics
  const { formatMessage } = useIntl()

  const columns = [
    { key: 'response', title: formatMessage(messages.answerColumnLabel), dataIndex: 'response' },
    { key: 'count', title: formatMessage(messages.answerCountLabel), dataIndex: 'count' },
    { key: 'share', title: formatMessage(messages.answerShareLabel), dataIndex: 'share' },
  ]
  const dataSource = statistics.map(({ label, count, relativeFrequency }) => ({
    response: label,
    count,
    share: `${Math.round(relativeFrequency * 100)} %`,
  }))

  return (
    <AnswerCard>
      <div className='flex flex-col gap-[24px] p-[24px]'>
        <div>
          <AnswerCard.Title title={label} />
          <Typography.Text type='secondary' strong>
            <FormattedMessage {...messages.xAnswers} values={{ count: answersCount }} />
          </Typography.Text>
        </div>
        <div className='max-h-[210px] overflow-y-auto'>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </div>
      </div>
    </AnswerCard>
  )
}
