import { Switch, type SwitchProps } from '@faceup/ui'
import { Button, type ButtonProps, Tooltip, type TooltipProps } from '@faceup/ui-base'
import { createPolymorphicComponent } from '@mantine/core'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  permissionTooltip: 'Administration.permissions.tooltip',
})

type Props = {
  areTooltipsDisabled?: boolean
  isFlex?: boolean
  children: ReactNode
  title?: ReactNode
  placement?: TooltipProps['placement']
}

// TODO: MD: 2023-04-17: I think we can remove this file now
// We need to wrap <Tooltip /> in element because of this weird bug
// https://github.com/react-component/tooltip/issues/18#issuecomment-650864750
const PermissionTooltipWrapper = ({
  areTooltipsDisabled = false,
  children,
  isFlex = true,
  title,
  placement,
}: Props) =>
  areTooltipsDisabled ? (
    <div style={{ display: isFlex ? 'flex' : 'inherit', alignItems: 'center', height: 'inherit' }}>
      {children}
    </div>
  ) : (
    <Tooltip
      title={title ?? <FormattedMessage {...messages.permissionTooltip} />}
      placement={placement}
    >
      <div
        style={{ display: isFlex ? 'flex' : 'inherit', alignItems: 'center', height: 'inherit' }}
      >
        {children}
      </div>
    </Tooltip>
  )

type PermissionButtonProps = ButtonProps
const _PermissionButton = (props: PermissionButtonProps) => (
  <div style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}>
    <Button {...props} />
  </div>
)

export const PermissionButton = createPolymorphicComponent<
  'button',
  PermissionButtonProps,
  'button'
>(_PermissionButton)

export const PermissionSwitch = ({ style, ...props }: SwitchProps) => (
  <div style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}>
    <Switch style={{ pointerEvents: props.disabled ? 'none' : undefined, ...style }} {...props} />
  </div>
)

export default PermissionTooltipWrapper
