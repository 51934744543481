import { Country } from './enums'

export const isCountryInEuropeanUnion = (country: Country) =>
  EUROPEAN_UNION_COUNTRIES.includes(country as EuCountries)

// European Union countries - https://en.wikipedia.org/wiki/Member_state_of_the_European_Union#List
const EUROPEAN_UNION_COUNTRIES = [
  Country.At,
  Country.Be,
  Country.Bg,
  Country.Cy,
  Country.Cz,
  Country.De,
  Country.Dk,
  Country.Ee,
  Country.Es,
  Country.Fi,
  Country.Fr,
  Country.Gr,
  Country.Hr,
  Country.Hu,
  Country.Ie,
  Country.It,
  Country.Lt,
  Country.Lu,
  Country.Lv,
  Country.Mt,
  Country.Nl,
  Country.Pl,
  Country.Pt,
  Country.Ro,
  Country.Se,
  Country.Si,
  Country.Sk,
] as const

// tell TypeScript that the type of this variable is a union of the values of the array
export type EuCountries = (typeof EUROPEAN_UNION_COUNTRIES)[number]
