import { UntitledIcon } from '@faceup/icons'
import { ulSlashCircle01 } from '@faceup/icons/ulSlashCircle01'
import { Link } from '@faceup/router'
import { Button, Typography } from '@faceup/ui-base'
import { Avatar, Center, Stack } from '@mantine/core'
import { sharedMessages } from './Shared/translations'
import { FormattedMessage, defineMessages } from './TypedIntl'

const messages = defineMessages({
  title: 'Administration.notFound.title',
})

const { Title } = Typography

const RouteNotFound = () => (
  <Center h='100%' sx={{ flex: 1 }}>
    <Stack align='center' maw={400} p={24} ta='center'>
      <Avatar color='red' size={80}>
        <UntitledIcon icon={ulSlashCircle01} />
      </Avatar>
      <Title level={1}>
        <FormattedMessage {...messages.title} />
      </Title>
      <Link to={routes => routes.home()}>
        <Button ghost type='primary'>
          <FormattedMessage {...sharedMessages.backHome} />
        </Button>
      </Link>
    </Stack>
  </Center>
)

export default RouteNotFound
